import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function Bottom(props) {

  




 
  

    return (
      <>
 <div className="bottom-nav">
        <div className="bottom-nav-title">
   
          

          <div className="bottom-nav-buttons">

            <div className="col-25">
            <div className="bnbt">
              ABOUT
            </div>
            <Link to="/history">
            <button className="bnb">
            <span className="arrow">⟶</span>VISION AND HISTORY
            </button>
            </Link>
            <Link to="/trainers">
            <button className="bnb">
            <span className="arrow">⟶</span>MEET OUR MASTER TRAINERS
            </button>
            </Link>
            </div>

            <div className="col-25">
            <div className="bnbt">
              PROGRAMS / COURSES
  
            </div>
            <div className="bnbc">
            No Experience Required | No Esthetician or Cosmetology License Needed for Any Course <br/><br/>Perfect for Career Transitioners or Anyone Interested in a Career in Beauty
            </div>

            <br/>

  
            <div className='bottom-navbar-btn  menu-btn navbar-button-box-shadow '>
            Fundamental Courses</div>
            <div className="bnb">
            All States except NJ 
            </div>
            <Link to="/courses/ombre">
            <button className="bnb">
            <span className="arrow">⟶</span>FEATHER NANO / POWDER OMBRE BROWS   <span className="assist ">&nbsp;|&nbsp; 3 DAYS + Optional Apprenticeship</span>
            </button>
            </Link>

            <Link to="/courses/ombre-lips">
            <button className="bnb">
            <span className="arrow">⟶</span>FEATHER NANO / POWDER OMBRE BROWS & WATERCOLOR LIP BLUSH  <span className="assist">&nbsp;|&nbsp; 5 DAYS + Optional Apprenticeship</span>
            </button>
            </Link>
            <Link to="/courses/lips">
            <button className="bnb">
            <span className="arrow">⟶</span>WATERCOLOR LIP BLUSH  <span className="assist">&nbsp;|&nbsp; 2 DAYS + Optional Apprenticeship</span>
            </button>
            </Link>

            <Link to="/courses/full-fundamentals">
            <button className="bnb">
            <span className="arrow">⟶</span>FULL FUNDAMENTALS - FEATHER NANO / POWDER OMBRE BROWS, WATERCOLOR LIP BLUSH, LASH ENHANCEMENT (EYELINER) <span className="assist">&nbsp;|&nbsp; 6 DAYS + Optional Apprenticeship</span>
            </button>
            </Link>
            <br/>

            <picture>
      <source className='navbar-aam l' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.webp" />
      <img className='navbar-aam l' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
      </picture>

            <div className='bottom-navbar-btn navbar-button-box-shadow platinum platinum-border'>
          AAM - 100 HOUR FUNDAMENTALS COURSE
          </div>
          
            <div className="bnb">
            Required for NJ Residents (those who will be practicing and working in New Jersey)</div>
            <Link to="/courses/aam-fundamentals">
            <button className="bnb">
            <span className="arrow">⟶</span>PLATINUM AAM 100-hr FUNDAMENTAL PERMANENT COSMETICS PRIMARY COURSE   <span className="assist ">&nbsp;|&nbsp; 6 DAYS + Required Apprenticeship
            |&nbsp;Techniques Learned: Feather Nano, Powder Ombré, Watercolor Lip Blush, Lash Enhancement/Eyeliner</span>
            </button>
            </Link>
            


  <br/>
            <div className='bottom-navbar-btn navbar-button-box-shadow gold'>
          MASTER COURSES</div>
          <div className="bnb">
            For Artists with Prior Machine Experience and Certification
            </div>
            <Link to="/courses/advanced-brows">
            <button className="bnb">
            <span className="arrow">⟶</span>MASTER FEATHER NANO BROWS<span className="assist">&nbsp;|&nbsp; 1 DAY + Optional Supervised Model Day Add-On </span>
            </button>
            </Link>
            <Link to="/courses/lash-enhancement">
            <button className="bnb">
            <span className="arrow">⟶</span>LASH ENHANCEMENT (EYELINER)<span className="assist">&nbsp;|&nbsp; 1 DAY </span>
            </button>
            </Link>
            <br/>

        

         <div className="bnb">
              FUNDAMENTALS GRADUATES
            </div>
          <Link to="/apprenticeship">
         <button className="bnb">
            <span className="arrow">⟶</span>APPRENTICESHIP
            </button>
            </Link>
<br/>
            <button className="bnb">
            CONTINUED EDUCATION COMING SOON...
            </button>
            </div>

            <div className="col-25">
            <div className="bnbt">
              REGISTRATION
            </div>
   
            <Link to="/financing">
            <button className="bnb">
            <span className="arrow">⟶</span>Financing Options / Loan Options
            </button>
            </Link>
            </div>
          </div>
        </div>



        <div className="social-links">
        <a target="_blank" href="https://www.instagram.com/cosmetinkbeauty">
        <i class="fa-brands fa-instagram"></i></a>
        <a target="_blank" href="https://www.youtube.com/channel/UCHBHoB48jZLahe7xSTfDKMw">
        <i class="fa-brands fa-youtube"></i></a>
        </div>
      
        <div className="bnbt">
        COSMETINK HEADQUARTERS
        </div>
        <div className="bnba">
          3859 Nazareth Pike, Unit 103<br/>
          Bethlehem, PA 18020<br/>
          <a className="lowercase" href="mailto:cosmetinkbeauty@gmail.com">cosmetinkbeauty@gmail.com</a>
        </div>
        <br/>
        <br/>
        <div className="bnba c">
        © 2024 COSMETINK, COSMETINK ACADEMY
        <br/> A<span className="lowercase">
        ll rights reserved.
          </span>
        </div>

  
      </div>




         </>
    );
    }