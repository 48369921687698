
import './navbar.css'

import React, { useState, useEffect } from 'react';
import {Link, useLocation} from 'react-router-dom'



export default function Navbar(props) {

  const [open, setOpen] = useState(false)
  const [mobileNav, setMobileNav] = useState(false)
  const [navblack, setNavblack] = useState(null)
  const [pos, setPos] = useState(false)
  const [showFundamentals, setShowFundamentals] = useState(false)
  const [showMasters, setShowMasters] = useState(false)



  useEffect(() => {

  }, [])

  const location = useLocation();

useEffect(() => {
  setNavblack(location.pathname)
}, [location.pathname]);



  window.addEventListener("scroll", handleScrollNav);

  function handleScrollNav() {
          
    const currentScrollPos = (window.pageYOffset);
  
    const visible = currentScrollPos > 10;

    setPos(visible)
    };

function setFundamentals() {
  showFundamentals ? setShowFundamentals(false) : setShowFundamentals(true) 
  
}

function setMasters() {
  showMasters ? setShowMasters(false) : setShowMasters(true) 
  
}

    

  function openMobileNav() {
    open ? setOpen(false) : setOpen(true)
    if (showFundamentals ) {setShowFundamentals(false) }
    if (showMasters) {setShowMasters(false) }
  }

  var href = window.location.href.split("/")[3]


    return (

      <div className="">

     
        

      <div className={!pos && navblack && navblack == "/" ? "large-navbar" : " large-navbar"}>

      <div className='new-navbar'>
        <button type="button" onClick={openMobileNav} className='hamburger'>
        <div className=''>
          <span className={!pos && navblack && navblack == "/" ? 'menu-name poppins-bold' : "menu-name poppins-bold"}>
            Menu
          </span>
         <span className='hamburger-box'>
        
          <span className={'hamburger-a'}>
          </span>
          <span className={'hamburger-b'}>
          </span>
   
         </span>

        </div>
        </button>
        </div>
        
        </div>

      
  

  {!pos && navblack && navblack == "/" ?
  <>
        <Link to={`/`}>
          <div className="logo-container fade-in">
          <div className='title wht c studio'>
          COSMET<span className='moving-grad'>INK</span>Academy 
                 
        <div className='under-title poppins-regular'>
        East Coast's Premier Accredited Permanent Cosmetics Training
        </div>
        </div>
                        
         </div>

        </Link>

        </> : <>
        <Link to={`/`}>
          <div className="logo-container fade-in">
          <div className={!pos && navblack && navblack == "/" ? 'title wht c studio' : 'title wht blk-color c studio'}>
                        COSMET<span className='moving-grad'>INK</span>Academy 
                        </div>
         </div>
        </Link>
        </> }

  

{open ?
      <div className='navbar-appears fade-in'>
        <div className='inside-navbar-appears'>
          <div className='inside-navbar-appears2'>
            {(showFundamentals || showMasters) ? null : <>
              <div onClick={openMobileNav} className='inline-nav-btn'>
          <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-l-10 pad-no-top">
               &nbsp;‹ Close</div>
              </div>
              <Link to="/">
              <div onClick={openMobileNav} className='inline-nav-btn'>
          <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-l-10 pad-no-top">
               &nbsp;Home</div>
              </div></Link>
              </>}
          <br/>



          {showFundamentals ? <>
            <div onClick={setFundamentals} className=''>
         
          <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-l-10 pad-no-top">
               &nbsp;‹ Back To Menu</div>
              </div><br/>
          </>: null}
        
          {showMasters ? null : <>
          
          
          <div onClick={setFundamentals}>
          <div className=''>
          <div className='bottom-navbar-btn  menu-btn navbar-button-box-shadow '>
          Fundamental Courses</div>
          </div>
          <div className='small-menu-btn'>
          For All States (except New Jersey)
          </div>
          </div>
          </>}

          {showFundamentals ? 
        
          <div className='slide-in-right width-100'>
        
                        <Link onClick={openMobileNav} to="/courses/ombre">



            <button className="bnb">
            <span className="arrow">⟶</span>FEATHER NANO / POWDER OMBRE BROWS   <span className="assist ">&nbsp;|&nbsp; 3 DAYS + Optional Apprenticeship</span>
            </button>

   
            
      




            </Link>

            <Link onClick={openMobileNav}  to="/courses/ombre-lips">
           
            <button className="bnb">
            <span className="arrow">⟶</span>FEATHER NANO / POWDER OMBRE BROWS & WATERCOLOR LIP BLUSH  <span className="assist">&nbsp;|&nbsp; 5 DAYS + Optional Apprenticeship</span>
            </button>
           




            </Link>
            <Link onClick={openMobileNav}  to="/courses/lips">

          
            <button className="bnb">
            <span className="arrow">⟶</span>WATERCOLOR LIP BLUSH  <span className="assist">&nbsp;|&nbsp; 2 DAYS + Optional Apprenticeship</span>
            </button>
           





            </Link>

            <Link onClick={openMobileNav}  to="/courses/full-fundamentals">

          
<button className="bnb">
<span className="arrow">⟶</span>FULL FUNDAMENTALS - FEATHER NANO / POWDER OMBRE BROWS, WATERCOLOR LIP BLUSH & LASH ENHANCEMENT (EYELINER)  <span className="assist">&nbsp;|&nbsp; 6 DAYS + Optional Apprenticeship</span>
</button>



</Link>

          </div> : null}




    

          {(showFundamentals || showMasters) ? null : 
          <>
          <Link onClick={openMobileNav} to="/courses/aam-fundamentals">
          <picture>
      <source className='navbar-aam' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.webp" />
      <img className='navbar-aam' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
      </picture>
          <div className='bottom-navbar-btn navbar-button-box-shadow platinum platinum-border'>
          AAM - 100 HOUR FUNDAMENTALS COURSE
          </div>
        
          <div className='small-menu-btn'>
          New Jersey Requirement
          </div>
  
   
         
          </Link>
          </>}


          {showMasters ? <>
            <div onClick={setMasters} className=''>
          <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-l-10 pad-no-top">
               &nbsp;‹ Back To Menu</div>
              </div><br/>
          </>: null}

          {showFundamentals ? null : <>
          
          <div onClick={setMasters}>
          <div className='bottom-navbar-btn navbar-button-box-shadow gold'>
          MASTER COURSES</div>
          <div className='small-menu-btn'>
          For Experienced Artists
          </div>
          </div>
          </>}


  
          {showMasters ? <>
            <Link onClick={openMobileNav}  to="/courses/advanced-brows">
            <button className="bnb">
            <span className="arrow">⟶</span>MASTER FEATHER NANO BROWS<span className="assist">&nbsp;|&nbsp; 1 DAY + Optional Supervised Model Day Add-On </span>
            </button>
            </Link>
            
            <Link onClick={openMobileNav}  to="/courses/lash-enhancement">
            <button className="bnb">
            <span className="arrow">⟶</span>LASH ENHANCEMENT (EYELINER)<span className="assist">&nbsp;|&nbsp; 1 DAY </span>
            </button>
            </Link>
            
            </> : null}
    


          <br/>
    
          {(showFundamentals || showMasters) ? null : 
          <>
          <Link onClick={openMobileNav} to="/apprenticeship">
          <div className='bottom-navbar-btn menu-btn2 navbar-button-box-shadow platinum2'>
          Apprenticeship
          </div>
          </Link>
          <Link onClick={openMobileNav} to="/faqs">
          <div className='bottom-navbar-btn menu-btn2 navbar-button-box-shadow platinum2'>
          FAQs
          </div>
          </Link>
          <Link onClick={openMobileNav} to="all-courses">
          <div className='bottom-navbar-btn menu-btn2 navbar-button-box-shadow platinum2'>
          ALL COURSE DATES
          </div>
          </Link>
          <Link onClick={openMobileNav} to="/location">
          <div className='bottom-navbar-btn menu-btn2 navbar-button-box-shadow platinum2'>
          LOCATION
          </div>
          </Link>
          <Link onClick={openMobileNav} to='/history'>
          <div className='bottom-navbar-btn menu-btn2 navbar-button-box-shadow platinum2'>
          ABOUT COSMETINK
          </div>
          </Link>
          <Link onClick={openMobileNav} to="/trainers">
          <div className='bottom-navbar-btn menu-btn2 navbar-button-box-shadow platinum2'>
          MEET THE INSTRUCTORS
          </div>
          </Link>
          </>}
        </div>
        </div>
      </div>

: null }

       

         <div className='bottom-course-nav'>
         <div className='right-btn'>
            <Link to="/all-courses" className='bottom-navbar-btn moving-gradient navbar-button-box-shadow'>
              All Course Dates
            </Link>
          </div>

      <div className='left-words poppins-medium'>
        Register Now
        <div className='left-words-2 poppins-regular-italic'>
        <span className='line'></span>
        See Upcoming Classes
        </div>
     
      </div>

      </div>

    {open ?
    <div onClick={openMobileNav}  className="navbar-underlay"></div> : null }
      </div>
        );
    }