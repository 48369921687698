
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function ProductDrop(props) {



  



 
  

    return (
      <>

<div className='left-box'>
            <h1 className='sanz-thin apple-h2'>
                    INK
                  </h1>
                  <h1 className='sanz-thin apple-h3'>
                    BRUSH.
                  </h1>
                  <div className='gray-font sanz-reg inkbrush-text fade-in'>It's Revolutionary.</div>
                  <div className="inkbrush-details">
                  Valued at $399<br/>
                  <a href="https://shop.cosmetink.com/cosmetink/collections/machines/cosmetink/inkbrush">View in Our Shop</a></div>
             
                <img className='inkbrush-image' src={`https://cosmetinkshop.s3.us-east-2.amazonaws.com/inkbrush.PNG`}></img>
                </div>
                
                <div className='right-box'>
                <h1 className='sanz-thin apple-h3a'>
                Powdery
               </h1>
                <h1 className='sanz-thin apple-h2a'>
                Pixel
                </h1>
                <br/>
                <img className='inkbrush-image inline-block' src={`https://cosmetinkshop.s3.us-east-2.amazonaws.com/IMG_2133.PNG`}></img>
                
                <div className='product-details'>
                  Box valued at $39
                  <br/>
                <a href="https://shop.cosmetink.com/cosmetink/collections/needles/cosmetink/detaileddefinition">
                  View in Our Shop
                </a>
                </div>
                </div>

                <div className='right-box'>
                <h1 className='sanz-thin apple-h3a blk'>
                  Detailed 
                </h1>
                <h1 className='sanz-thin apple-h2a'>
                  Definition
                </h1>
                <br/>
                <img className='inkbrush-image inline-block' src={`https://cosmetinkshop.s3.us-east-2.amazonaws.com/IMG_2131.PNG`}></img>
                <div className='product-details'>
                  Box valued at $32
                  <br/>
                <a className="product-detail-shop-link" href="https://shop.cosmetink.com/cosmetink/collections/needles/cosmetink/powderypixel">
                  View in Our Shop
                </a>
                </div></div> </>
    );
    }