import * as Util from '../util/course_api_util'

export const RECEIVE_AVAILABLE_COURSES = "RECEIVE_AVAILABLE_COURSES"
export const RECEIVE_USER_COURSES = "RECEIVE_USER_COURSES";
export const RECEIVE_NEW_COURSE = "RECEIVE_NEW_COURSE";
export const RECEIVE_COURSE_SECTIONS = "RECEIVE_COURSE_SECTIONS";
export const RECEIVE_CHAPTERS = "RECEIVE_CHAPTERS";
export const RECEIVE_ASSIGNMENTS = "RECEIVE_ASSIGNMENTS"

export const receiveAvailableCourses = courses => ({
    type: RECEIVE_AVAILABLE_COURSES,
    courses
})

export const receiveUserCourses = usercourses => ({
    type: RECEIVE_USER_COURSES,
    usercourses
})

export const receiveNewCourse = newCourse => ({
    type: RECEIVE_NEW_COURSE,
    newCourse
})

export const receiveCourseSections = sections => ({
    type: RECEIVE_COURSE_SECTIONS,
    sections
})

export const receiveChapters = chapters => ({
    type: RECEIVE_CHAPTERS,
    chapters
})

export const receiveAssignments = assignments => ({
    type: RECEIVE_ASSIGNMENTS,
    assignments
})

export const fetchAvailableCourses = () => dispatch => {
    Util.fetchAvailableCourses().then(courses => {
        dispatch(receiveAvailableCourses(courses))
    })
}

export const fetchUserCourses = (user_id) => dispatch => {
    Util.fetchUserCourses(user_id).then(usercourses => {
        dispatch(receiveUserCourses(usercourses))
    })
}

export const signupCourse = (user_id, courseData ) => dispatch => {
    Util.signupCourse(user_id, courseData).then(newCourse => {
        dispatch(receiveNewCourse(newCourse))
    })
}

export const fetchCourseMaterial = (course_id) => dispatch => {
    Util.fetchCourseMaterial(course_id).then(sections => {
        dispatch(receiveCourseSections(sections))
    })
}

export const fetchChapters = (course_id) => dispatch => {
    Util.fetchChapters(course_id).then(chapters => {
        dispatch(receiveChapters(chapters))
    })
}

export const fetchAssignments = (course_id) => dispatch => {
    Util.fetchAssignments(course_id).then(assignments => {
        dispatch(receiveAssignments(assignments))
    })
}