import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";


export default function History(props) {



    const [open, setOpen] = useState(null)

  
    function setOpenSection(num) {
      if (open === num) {
        setOpen(null)
      } else {
        setOpen(num)
      }
    }
  
    useEffect(() => {
      window.scrollTo(0, 0)
       
       }, [])




 
  

    return (
      <div className="individual-course-page c desktop-view">




<img className="image-100" src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acad2.JPG" alt="permanent makeup training microblading training learn microblading learn permanent makeup permanent makeup courses"/>

        


         <div className="artist-box">
         Nikky Altemose is the visionary CEO behind
COSMETINK®, the go-to permanent makeup
studio in the Lehigh Valley, where she and her team
have performed over 8,000 beauty transformations.
This state-of-the-art studio offers the most
advanced techniques in microblading, ombré
brows, lip blushing and eyeliner tattoos.
“Our timeless brow shapes and our elevated eye
for beauty have really set us apart and catapulted
us to where we are now,” Nikky says. “We just want
to create beautiful looks that transcend time and
trends.” Nikky has been cultivating her eye since
her start in the beauty industry as a professional
makeup artist in 2010. Upon discovering a true
love for permanent makeup in 2015, she eventually
founded COSMETINK® two years later.
Nikky’s skill set comes from years of training
with world-renowned artists, keeping her up to
date with the latest, most advanced techniques,
all of which get passed down to her team and
her students.
As a young woman entrepreneur, Nikky has
broken the glass ceiling, becoming a leading
employer and educator for aspiring artists
and entrepreneurs.
<br/><br/>
<img className="image-100" src="https://cosacademyassets.s3.us-east-2.amazonaws.com/Nikkylvs.jpg">
    
</img>
<br/><br/>
In founding the Lehigh Valley’s first permanent
cosmetics academy and being recognized as a
master trainer by the internationally renowned
Beauty Angels®, Nikky trains and mentors
young women and men interested in joining the
fast-growing industry, including many who have
already gone off and successfully opened their own
businesses. With the academy offering regular
certification programs in various permanent
makeup techniques, Nikky aims to create more
young entrepreneurs in the Valley and beyond.
</div>



         </div>
    );
    }