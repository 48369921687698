import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom'
import ProductDrop from './product_drop';


export default function AvailableCourses(props) {

  const [open, setOpen] = useState(null)
  const [description, setDescription] = useState(null)
  const [register, setRegister] = useState(false)
  const [mOpen, setmOpen] = useState(false)
  const [aOpen, setaOpen] = useState(false)
  const [sOpen, setsOpen] = useState(false)
  const [courseDetail, setCourseDetail] = useState(null)
  const [courseType, setCourseType] = useState(null)
  const params = useParams();

  const { fetchAvailableCourses,
    courses, type, key
   } = props

   useEffect(() => {
    window.scrollTo(0, 0)
  
  }, [])


useEffect(() => {
fetchAvailableCourses() 

}, [])

useEffect(() => {
  if (params.name && !courseType || courseType !== params.name)
    setCourseType(params.name)
    window.scrollTo(0, 0)
  }, [params.name])

function setM() {
  mOpen ? setmOpen(false) : setmOpen(true) 
  
}

function setA() {
  aOpen ? setaOpen(false) : setaOpen(true) 
  
}

function setS() {
  sOpen ? setsOpen(false) : setsOpen(true) 
  
}

function openCourse(num) {
  setCourseDetail(num)
}



if (courseType && courseType === "ombre-lips") {
  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">
    <video poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnposter.webp" loop muted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyvideos.s3.us-east-2.amazonaws.com/aamvideo.MP4`}/>

                <div className="mobile-banner-overlay">
                </div>
                <div className='course-title-top'>
                  Feather Nano / Powder Ombré Brows<br/>+ WaterColor Lips
                </div>
                <div className="remaining-title">
                  5 Day Fundamental Training <br/>
                  <span className="italic smaller wht">with Optional Apprenticeship</span>
                </div>
                </div>
                </div>
                <br/>
      




                <div className='photo-wrap'>


                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
                </picture>
                </div>
                
                </div>

    
      
                <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title pad-l-10">
                Upcoming Class Dates
                </div>
                
                

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            {courses[key].bookNow}
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }

                <div className='bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10'>
           
          HOW TO BECOME CERTIFIED
              <div className='class-cost'>
              ✓ Complete Prep Work 2 weeks prior to Class & Obtain a Bloodborne Pathogen Training For Body Art Certificate from ProTrainings<br/>
              ✓ Attend the 5-Day In-Person Training at COSMETINK Academy<br/>
              ✓ Satisfactorily Complete 2 Live Models with Supervision (In Class)<br/>
         
                </div>
                
        </div>
              


                <div className={"width-90c"}>
                <div className="paragraph-title">
                DETAILS
                </div>
                <div className="class-detail-title">
                  Total Cost
                </div>
                <div className="class-cost">
                  $6000 (includes full kit worth over $900 value)
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $950 non-refundable deposit 
                  <br/>
                  Remaining balance of $5050 due one week before course dates
                </div>
                <div className="class-detail-title">
                 Information
                </div>
                <div className="class-cost">
In this rigorous, multi-day course, you will learn COSMETINK®'s Signature FEATHER NANO, OMBRÉ BROW, & WATERCOLOR LIP BLUSHING technique from start to finish, ensuring to propel you into the industry as a top permanent makeup artist.

<br/><br/>
Our Modern Art of Permanent Cosmetics "Fundamentals" training classes are the best choice for both beginners and those looking to improve their knowledge in the cosmetic tattoo industry. 
<br/><br/>
This in-depth instruction includes the correct way to hold your machine for proper pigment implantation, mastering the pendulum motion, finding your personal hand speed to create your own unique beautiful work, color theory, skin anatomy, and more. 
<br/><br/>We will personally guide you through every aspect of permanent makeup, including theory instruction & hands on experience working on live models. <br/>
<br/>
You will learn how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.<br/><br/>

Students leave this class confident in their abilities and ready to put their new knowledge into practice.

<div className="paragraph-title">
                INSTILLING CONFIDENCE
</div>
Click on the video to watch our students in action demonstrating how this class gave them the confidence to do their best work.
<div className='c'>
<br/>
        <a alt="learn permanent makeup" href="https://cosacademyvideos.s3.us-east-2.amazonaws.com/confidentvideo.mp4">
        <picture>
        <source className='video-thumb' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.webp" />
        <img className='video-thumb' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.jpg" />
        </picture>
        <div className='play-button'>
        <i class="fa-duotone fa-play"></i>
        </div>
        </a>


</div>
<br/><br/>
Students will receive a professional kit of the highest quality permanent makeup products, including our revolutionary COSMETINK® INKBRUSH™ machine. Your kit includes all supplies needed to perform our signature techniques and get started in your new career. 
</div>
               <div className="paragraph-title">
                FULL KIT INCLUDED
                </div>

                <div className="class-detail-title">
                What's In My Kit?
                </div>
                <div className='class-cost'>
                Your kit is valued at $900 and includes the following EXCLUSIVE COSMETINK® supplies: <br/>
                <br/>○ INKBRUSH™ machine ($399 value)
                <br/>○ 2 boxes of our DETAILED DEFINITION™ Needles ($39 value)
                <br/>○ 1 box of our POWDERY PIXEL™ Needles ($32 value)
                <br/>○ PERFECT BROWS™ Mapping Stickers
                <br/>○ Brow mapping supplies (including our PERFECT BROWS™ mapping string, mapping pencils, concealer brush)
                <br/><br/>other supplies includes: <br/><br/>
                brow razors, ink cups, practice latex with pre-drawn brow and lip outlines, 3D lip latex molds, and stroke patterns, lip wands, numbing cream, machine grip tape, clip cord sleeve covers, machine power supply, and full sets of eyebrow and lip pigments
            </div>
                 
                <ProductDrop />


                </div>
                <br/>
                  <div className="paragraph-title c">
                 CLASS CURRICULUM
                </div>
                <div className="curriculum-area">
      

                <div className="row row-a">
    Feather Nano / Powder Ombré Brows & Watercolor Lips<br/><span className="italic smaller">5 Hours of At-Home Pre-Course Online Work + 4 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    Option to participate in apprenticeship program: complete 5 live models in our facility within 6 months of course completion.  You will earn a 100hr Certification by completing this.
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes our Signature InkBrush™ PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Social Media In the Industry
    </div>
    <div className="row row-a">
    Modern Marketing, Pricing Strategies, How to Attract Clients
    </div>
    <div className="row row-b">
    Skin Theory & Depth for Permanent Makeup
    </div>
    <div className="row row-a">
    Color Theory & Proper Pigmentation
    </div>
    <div className="row row-b">
    The Science of Beauty: Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-a">
    COSMETINK®'s Signature Machine Technique to create Powdery Brows and Luscious, Watercolor Lips. 
    <br/><br/>Focus on creating brows with Beautiful Gradients & Definition and Lips with Plush Color and Defined Cupid's Bows.

    </div>
    <div className="row row-b">
    Hands-on Latex Practice with Individualized Guidance
    </div>
    <div className="row row-a">
    You will work on 2 Live Models under the guidance and supervision of our Master Trainers.<br/>
    <br/>One Brow Model; One Lip Model
    </div>
    <div className="row row-b">
    Certification as a COSMETINK® PRO Artist<br/><br/>Clients and potential employers know the reputation of our Academy and success rate of our students.  
    By entering the industry as a COSMETINK® Pro Artist, you are going to stand out as a Top Artist.
    </div>
    </div>



    <div className='details width-90c'>
<div className="class-detail-title ">
                  Important Details
                </div>
     
                <div className='class-cost'>
                  
                This will consist of an at-home AND in-person component. At-home preparation work will be sent to you two weeks prior to the course dates, and will require approximately 5-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
               
                <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.

                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous permanent makeup done on the area of focus.  Please be sure to choose someone who will be patient, understanding, and comfortable with you. Models must be 18 years or older.
                </div>


                </div>

            
            
            
      
                


  
    <div className="div-spacer"></div>
</div>
  )
}

if (courseType && courseType === "aam-fundamentals") {
  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">

      <div className='mobile-banner-left-top'>
        <picture>
        <source className='navbar-aam l' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.webp" />
        <img className='navbar-aam l' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
        </picture>
    
        <div className='bottom-navbar-btn navbar-button-box-shadow platinum platinum-border'>
          AAM - 100 HOUR FUNDAMENTALS COURSE
        </div>
        </div>
        <video poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnposter.webp" loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyvideos.s3.us-east-2.amazonaws.com/aamvideo.MP4`}/>


                <div className="mobile-banner-overlay">
                </div>
                <div className='course-title-top'>
                  Platinum AAM 100-HR Permanent Cosmetics Fundamental Course
                </div>
                <div className="remaining-title">
                  6 Day Fundamental Training <br/>
                  <span className="italic smaller wht">with Apprenticeship (required for NJ, optional for PA)</span>
                </div>
                </div>
                </div>
                <br/>  

                
                <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10">
                Techniques Learned
          
                <div className='class-cost'>
                Feather Nano Brows, Powder Ombré Brows, Watercolor Lip Blush, Lash Enhancement
                </div>
                
                </div>
              
          

            
                <div className='photo-wrap'>


                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
                </picture>
                </div>
                
                </div>

          
      
                <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title pad-l-10">
                Upcoming Class Dates
                </div>
                
                
                

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            {courses[key].bookNow}
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <br/>




                <div className='bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10'>
                <picture>
        <source className='navbar-aam l' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.webp" />
        <img className='navbar-aam l' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
        </picture><br/>
          HOW TO BECOME AAM CERTIFIED (required for NJ students, optional for PA students)
              <div className='class-cost'>
              ✓ Complete prep work 2 weeks prior to class & obtain a Bloodborne Pathogen Training for Body Art Certificate from ProTrainings<br/>
              ✓ Attend the 6-Day In-Person Training at COSMETINK Academy<br/>
              ✓ Satisfactorily complete 3 Live Models (in class)<br/>
              ✓ Plan and Complete 12 Additional Models after graduation<br/>
              ✓ Take and Pass the AAM Board Exam<br/>
                </div>
                
        </div>


                <div className={"width-90c"}>
                <div className="paragraph-title">
                DETAILS
                </div>
                <div className="class-detail-title">
                  Total Cost
                </div>
                <div className="class-cost">
                  $7500 (includes full kit worth over $900 value)<br/>+ 12 additional apprenticeship models (required for NJ / optional for PA) - $150 each (due at the time of session)<br/>
                  AAM Board Exam - $397 paid directly to AAM Board
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $1250 non-refundable deposit 
                  <br/>
                  Remaining balance of $6250 due one week before course dates
                  <br/>
                  Your in-class models are done with no additional charge.
                </div>
                <div className="class-detail-title">
                 Information
                </div>
                <div className="class-cost">
In this rigorous, multi-day course, you will learn COSMETINK®'s Signature FEATHER NANO, OMBRÉ BROW, WATERCOLOR LIP BLUSHING, & Lash Enhancement techniques from start to finish, ensuring to propel you into the industry as a top permanent makeup artist in the industry.

<br/><br/>
Our Modern Art of Permanent Cosmetics "Fundamentals" training classes are the best choice for both beginners and those looking to improve their knowledge in the cosmetic tattoo industry. 
<br/><br/>
This in-depth instruction includes the correct way to hold your machine for proper pigment implantation, mastering the pendulum motion, finding your personal hand speed to create your own unique beautiful work, color theory, skin anatomy, and more. 
<br/><br/>We will personally guide you through every aspect of permanent makeup, including theory instruction & hands on experience working on live models. <br/>
<br/>
You will learn how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.<br/><br/>

Students leave this class confident in their abilities and ready to put their new knowledge into practice.

<div className="paragraph-title">
                INSTILLING CONFIDENCE
</div>
Click on the video to watch our students in action demonstrating how this class gave them the confidence to do their best work.
<div className='c'>
<br/>
        <a alt="learn permanent makeup" href="https://cosacademyvideos.s3.us-east-2.amazonaws.com/confidentvideo.mp4">
        <picture>
        <source className='video-thumb' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.webp" />
        <img className='video-thumb' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.jpg" />
        </picture>
        <div className='play-button'>
        <i class="fa-duotone fa-play"></i>
        </div>
        </a>


</div>
<br/><br/>
Students will receive a professional kit of the highest quality permanent makeup products, including our revolutionary COSMETINK® INKBRUSH™ machine. Your kit includes all supplies needed to perform our signature techniques and get started in your new career. 
</div>
               <div className="paragraph-title">
                FULL KIT INCLUDED
                </div>

                <div className="class-detail-title">
                What's In My Kit?
                </div>
                <div className='class-cost'>
                Your kit is valued at $900 and includes the following EXCLUSIVE COSMETINK® supplies: <br/>
                <br/>○ INKBRUSH™ machine ($399 value)
                <br/>○ 2 boxes of our DETAILED DEFINITION™ Needles ($39 value)
                <br/>○ 1 box of our POWDERY PIXEL™ Needles ($32 value)
                <br/>○ PERFECT BROWS™ Mapping Stickers
                <br/>○ Brow mapping supplies (including our PERFECT BROWS™ mapping string, mapping pencils, concealer brush)
                <br/><br/>other supplies includes: <br/><br/>
                brow razors, ink cups, practice latex with pre-drawn brow and lip outlines, 3D lip latex molds, and stroke patterns, lip wands, numbing cream, machine grip tape, clip cord sleeve covers, machine power supply, and full sets of eyebrow and lip pigments
            </div>
                 
                <ProductDrop />


                </div>
                <br/>
                  <div className="paragraph-title c">
                 CLASS CURRICULUM
                </div>
                <div className="curriculum-area">
      

                <div className="row row-a">
    Feather Nano / Powder Ombré Brows, Watercolor Lips & Lash Enhancement<br/><span className="italic smaller">5 Hours of At-Home Pre-Course Online Work + 4 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    100 hr Certification after completing the course + apprenticeship
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes our Signature InkBrush™ PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Social Media In the Industry
    </div>
    <div className="row row-a">
    Modern Marketing, Pricing Strategies, How to Attract Clients
    </div>
    <div className="row row-b">
    Skin Theory & Depth for Permanent Makeup
    </div>
    <div className="row row-a">
    Color Theory & Proper Pigmentation
    </div>
    <div className="row row-b">
    The Science of Beauty: Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-a">
    COSMETINK®'s Signature Machine Technique to create Powdery Brows and Luscious, Watercolor Lips. 
    <br/><br/>Focus on creating brows with Beautiful Gradients & Definition and Lips with Plush Color and Defined Cupid's Bows.

    </div>
    <div className="row row-b">
    Hands-on Latex Practice with Individualized Guidance
    </div>
    <div className="row row-a">
    You will work on 3 Live Models under the guidance and supervision of our Master Trainers.<br/>
    <br/>One Brow Model; One Lip Model; One Lash Enhancement Model
    </div>
    <div className="row row-b">
    Certification as a COSMETINK® PRO Artist<br/><br/>Clients and potential employers know the reputation of our Academy and success rate of our students.  
    By entering the industry as a COSMETINK® Pro Artist, you are going to stand out as a Top Artist.
    </div>
    </div>



    <div className='details width-90c'>
<div className="class-detail-title ">
                  Important Details
                </div>
     
                <div className='class-cost'>
                  
                This will consist of an at-home AND in-person component. At-home preparation work will be sent to you two weeks prior to the course dates, and will require approximately 5-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
               
                <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.

                <br/><br/>*You are responsible for providing models for your live model work.  Models must not have had previous permanent makeup done on the area of focus.  Please be sure to choose someone who will be patient, understanding, and comfortable with you. Models must be 18 years or older.
                </div>


           </div></div>

        
            
                
      
                


  
  
  )
}


if (courseType && courseType === "ombre") {
  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">

   
    <video poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnposter.webp" loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyvideos.s3.us-east-2.amazonaws.com/feathernano_ombre.mp4`}/>

                <div className="mobile-banner-overlay">
                </div>
              
                <div className='course-title-top'>
                 Feather Nano / Powder Ombré Brows
                </div>
                                        <div className="remaining-title ">
                                          3 Day Fundamental Training <br/>
                                          <span className="italic smaller wht">with Optional Apprenticeship</span>
                                        </div>
                </div></div>

                <br/>
      




                <div className='photo-wrap'>


                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
                </picture>
                </div>


                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
                </picture>
                </div>
                
                </div>
      
                <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title pad-l-10">
                Upcoming Class Dates
                </div>
                
             
                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            {courses[key].bookNow}
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }

                <div className='bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10'>
           
           HOW TO BECOME CERTIFIED
               <div className='class-cost'>
               ✓ Complete Prep Work 2 weeks prior to Class & Obtain a Bloodborne Pathogen Training For Body Art Certificate from ProTrainings<br/>
               ✓ Attend the 3-Day In-Person Training at COSMETINK Academy<br/>
               ✓ Satisfactorily Complete Live Model with Supervision (In Class)<br/>
          
                 </div>
                 </div>

                <div className={"width-90c"}>
                <div className="paragraph-title">
                DETAILS
                </div>
                <div className="class-detail-title">
                  Total Cost
                </div>
                <div className="class-cost">
                  $4000 (includes full kit worth $900 value)
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $600 non-refundable deposit 
                  <br/>
                  Remaining balance of $3400 due one week before course dates
                </div>
                <div className="class-detail-title">
                 Information
                </div>
                <div className="class-cost">
In this rigorous 3 day course, you will learn COSMETINK®'s Signature FEATHER NANO & OMBRÉ BROW technique from start to finish, ensuring to propel you into the industry as a top permanent makeup artist.

<br/><br/>
Our Modern Art of Permanent Cosmetics "Fundamentals" training classes are the best choice for both beginners and those looking to improve their knowledge in the cosmetic tattoo industry. 
<br/><br/>
This in-depth instruction includes the correct way to hold your machine for proper pigment implantation, mastering the pendulum motion, finding your personal hand speed to create your own unique beautiful work, color theory, skin anatomy, and more. 
<br/><br/>We will personally guide you through every aspect of permanent makeup, including theory instruction & hands on experience working on live models. <br/>
<br/>
You will learn how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.<br/><br/>

Students leave this class confident in their abilities and ready to put their new knowledge into practice.

<div className="paragraph-title">
                INSTILLING CONFIDENCE
</div>
Click on the video to watch our students in action demonstrating how this class gave them the confidence to do their best work.
<div className='c'>
<br/>
        <a className="" alt="learn permanent makeup" href="https://cosacademyvideos.s3.us-east-2.amazonaws.com/confidentvideo.mp4">
        <picture>
        <source className='video-thumb' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.webp" />
        <img className='video-thumb' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.jpg" />
        </picture>
        <div className='play-button'>
        <i class="fa-duotone fa-play"></i>
        </div>
        </a>


</div>
<br/><br/>
Students will receive a professional kit of the highest quality permanent makeup products, including our revolutionary COSMETINK® INKBRUSH™ machine. Your kit includes all supplies needed to perform our signature techniques and get started in your new career. 
</div>
               <div className="paragraph-title">
                FULL KIT INCLUDED
                </div>

                <div className="class-detail-title">
                What's In My Kit?
                </div>
                <div className='class-cost'>
                Your kit is valued at $900 and includes the following EXCLUSIVE COSMETINK® supplies: <br/>
                <br/>○ INKBRUSH™ machine ($399 value)
                <br/>○ 1 box of our DETAILED DEFINITION™ Needles ($39 value)
                <br/>○ 1 box of our POWDERY PIXEL™ Needles ($32 value)
                <br/>○ PERFECT BROWS™ Mapping Stickers
                <br/>○ Brow mapping supplies (including our PERFECT BROWS™ mapping string, mapping pencils, concealer brush)
                <br/><br/>other supplies includes: <br/><br/>
                brow razors, ink cups, practice latex with pre-drawn brow outlines and stroke patterns, lip wands, numbing cream, machine grip tape, clip cord sleeve covers, machine power supply, and full set of eyebrow pigments
            </div>
                 
                <ProductDrop />


                </div>
                  <br/>
                  <div className="paragraph-title c">
                 CLASS CURRICULUM
                </div>
                <div className="curriculum-area">
      


  <div className="row row-a">
    Feather Nano & Powder Ombré Brows<br/><span className="italic smaller">5 Hours of At-Home Pre-Course Online Work + 2 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    Option to participate in apprenticeship program: complete 5 live models in our facility within 6 months of course completion.  You will earn a 100hr Certification by completing this.
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes our Signature InkBrush™ PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Social Media In the Industry
    </div>
    <div className="row row-a">
    Modern Marketing, Pricing Strategies, How to Attract Clients
    </div>
    <div className="row row-b">
    Skin Theory & Depth for Permanent Makeup
    </div>
    <div className="row row-a">
    Color Theory & Proper Pigmentation
    </div>
    <div className="row row-b">
    The Science of Beauty: Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-a">
    COSMETINK®'s Signature Machine Technique to create Powdery Brow and Beautiful Hairstrokes. 
    <br/><br/>Focus on creating brows with Beautiful Gradients & Definition as well as Realistic Hairstrokes.

    </div>
    <div className="row row-b">
    Demo Work by Master Trainers & Hands-on Latex Practice with Individualized Guidance
    </div>
    <div className="row row-a">
    You will work on a Live Model under the guidance and supervision of our Master Trainers.
    </div>
    <div className="row row-b">
    Certification as a COSMETINK® PRO Artist<br/><br/>Clients and potential employers know the reputation of our Academy and success rate of our students.  
    By entering the industry as a COSMETINK® Pro Artist, you are going to stand out as a Top Artist.
    </div>
    </div>

<div className='details width-90c'>
<div className="class-detail-title ">
                  Important Details
                </div>
     
                <div className='class-cost'>
                  
                This will consist of an at-home AND in-person component. At-home preparation work will be sent to you two weeks prior to the course dates, and will require approximately 5-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
               
                <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.

                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous permanent makeup done on the area of focus.  Please be sure to choose someone who will be patient, understanding, and comfortable with you. Models must be 18 years or older.
                </div>


                </div>

     

              



      
      



  

        
            
            
            
      
                


    <div className="div-spacer"></div>
  
</div>
  )
}



if (courseType && courseType === "lips") {
  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">

    <div className="mobile-banner">
    <video poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" loop muted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyvideos.s3.us-east-2.amazonaws.com/aamvid.mp4`}/>

<div className="mobile-banner-overlay">
</div>
<div className='course-title-top'>
  WaterColor Lips
</div>
<div className="remaining-title">
  2 Day Fundamental Training <br/>
  <span className="italic smaller wht">with Optional Apprenticeship</span>
</div>
</div>
</div>
<br/>





<div className='photo-wrap'>




<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
</picture>
</div>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
</picture>
</div>





<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
</picture>
</div>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
</picture>
</div>

</div>



<div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title pad-l-10">
Upcoming Class Dates
</div>


             

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
            

            return (
              <div className="course-wrap">
                
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            Enroll Now
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }

                <div className='bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10'>
           
           HOW TO BECOME CERTIFIED
               <div className='class-cost'>
               ✓ Complete Prep Work 2 weeks prior to Class & Obtain a Bloodborne Pathogen Training For Body Art Certificate from ProTrainings<br/>
               ✓ Attend the 2-Day In-Person Training at COSMETINK Academy<br/>
               ✓ Satisfactorily Complete Live Model with Supervision (In Class)<br/>
          
                 </div>
                 </div>


                <div className={"width-90c"}>
                <div className="paragraph-title">
                DETAILS
                </div>
                <div className="class-detail-title">
                  Total Cost
                </div>
                <div className="class-cost">
                  $3800 (includes full kit worth over $900 value)
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $600 non-refundable deposit 
                  <br/>
                  Remaining balance of $3200 due one week before course dates
                </div>
                <div className="class-detail-title">
                 Information
                </div>
                <div className="class-cost">
In this rigorous 2 day course, you will learn COSMETINK®'s Signature WATERCOLOR LIP BLUSHING technique from start to finish, ensuring to propel you into the industry as a top permanent makeup artist.

<br/><br/>
Our Modern Art of Permanent Cosmetics "Fundamentals" training classes are the best choice for both beginners and those looking to improve their knowledge in the cosmetic tattoo industry. 
<br/><br/>
This in-depth instruction includes the correct way to hold your machine for proper pigment implantation, mastering the pendulum motion, finding your personal hand speed to create your own unique beautiful work, color theory, skin anatomy, and more. 
<br/><br/>We will personally guide you through every aspect of permanent makeup, including theory instruction & hands on experience working on live models. <br/>
<br/>
You will learn how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.<br/><br/>

Students leave this class confident in their abilities and ready to put their new knowledge into practice.

<div className="paragraph-title">
                INSTILLING CONFIDENCE
</div>
Click on the video to watch our students in action demonstrating how this class gave them the confidence to do their best work.
<div className='c'>
<br/>
        <a alt="learn permanent makeup" href="https://cosacademyvideos.s3.us-east-2.amazonaws.com/confidentvideo.mp4">
        <picture>
        <source className='video-thumb' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.webp" />
        <img className='video-thumb' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.jpg" />
        </picture>
        <div className='play-button'>
        <i class="fa-duotone fa-play"></i>
        </div>
        </a>


</div>
<br/><br/>
Students will receive a professional kit of the highest quality permanent makeup products, including our revolutionary COSMETINK® INKBRUSH™ machine. Your kit includes all supplies needed to perform our signature techniques and get started in your new career. 
</div>
               <div className="paragraph-title">
                FULL KIT INCLUDED
                </div>

                <div className="class-detail-title">
                What's In My Kit?
                </div>
                <div className='class-cost'>
                Your kit is valued at $900 and includes the following EXCLUSIVE COSMETINK® supplies: <br/>
                <br/>○ INKBRUSH™ machine ($399 value)
                <br/>○ 1 box of our DETAILED DEFINITION™ Needles ($39 value)
              
               
                <br/>○ Lip mapping supplies (mapping pencils & paste, mapping pen, concealer brush)
                <br/><br/>other supplies includes: <br/><br/>
                 ink cups, practice latex with pre-drawn lip outlines and stroke patterns, lip wands, numbing cream, machine grip tape, clip cord sleeve covers, machine power supply, and full set of lip pigments
            </div>
                 
                <ProductDrop />


                </div>
                <br/>
                  <div className="paragraph-title c">
                 CLASS CURRICULUM
                </div>
                <div className="curriculum-area">
      

                <div className="row row-a">
    COSMETINK's Signature Watercolor Lips Technique<br/><span className="italic smaller">5 Hours of At-Home Pre-Course Online Work + 2 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    Option to participate in apprenticeship program: complete 5 live models in our facility within 6 months of course completion.  You will earn a 100hr Certification by completing this.
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes our Signature InkBrush™ PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Social Media In the Industry
    </div>
    <div className="row row-a">
    Modern Marketing, Pricing Strategies, How to Attract Clients
    </div>
    <div className="row row-b">
    Skin Theory & Depth for Permanent Makeup
    </div>
    <div className="row row-a">
    Color Theory & Proper Pigmentation
    </div>
    <div className="row row-b">
    The Science of Beauty: Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-a">
    COSMETINK®'s Signature Machine Technique to create Powdery Brows. 
    <br/><br/>Focus on creating brows with Beautiful Gradients & Definition.

    </div>
    <div className="row row-b">
    Demo Work by Master Trainers & Hands-On Latex Practice with Individualized Guidance
    </div>
    <div className="row row-a">
    You will work on a Live Model under the guidance and supervision of our Master Trainers.
    </div>
    <div className="row row-b">
    Certification as a COSMETINK® PRO Artist<br/><br/>Clients and potential employers know the reputation of our Academy and success rate of our students.  
    By entering the industry as a COSMETINK® Pro Artist, you are going to stand out as a Top Artist.
    </div>
    </div>



    <div className='details width-90c'>
<div className="class-detail-title ">
                  Important Details
                </div>
     
                <div className='class-cost'>
                  
                This will consist of an at-home AND in-person component. At-home preparation work will be sent to you two weeks prior to the course dates, and will require approximately 5-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
               
                <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.

                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous permanent makeup done on the area of focus.  Please be sure to choose someone who will be patient, understanding, and comfortable with you. Models must be 18 years or older.
                </div>


                </div>

            
            
            


                <div className="div-spacer"></div>
           
        
  
</div>
  )
}


if (courseType && courseType === "advanced-brows") {
  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">

    <div className="mobile-banner">
    <video poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnposter.webp" loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/feathernano_only.mp4`}/>

<div className="mobile-banner-overlay">
</div>

<div className='course-title-top'>
 Feather Nano Brows: Master Series
</div>
                        <div className="remaining-title ">
                          1 Day Advanced Training<br/>
                          <span className="italic smaller wht">with Optional Supervised Model Day Add-On</span>
                        </div>
</div></div><br/>

<div className="bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10">
                For Currently Certified Artists | Machine Experience Required
          
              
                
                </div>

<br/>





<div className='photo-wrap'>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
</picture>
</div>

</div>

<div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title pad-l-10">
Upcoming Class Dates
</div>
              

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
            

            return (
              <div className="course-wrap">
                
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            Enroll Now
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <div className={"width-90c"}>
                <div className="paragraph-title">
                DETAILS
                </div>
                <div className="class-detail-title">
                  Total Cost
                </div>
                <div className="class-cost">
                  $3000 (includes full kit worth $400 value)<br/>
                  Optional Model Day Add-On: $500 (Must be completed the day after Instruction Day)
                  <a href="mailto:cosmetinkbeauty@gmail.com?subject=COSMETINK ACADEMY MASTER CLASS&body=Please give me more information about the upcoming Feather Nano Class for previous COSMETINK students." className="regular"><br/>Previous graduates of COSMETINK Academy: email for discounted rate</a><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $600 non-refundable deposit 
                  <br/>
                  Remaining balance of $2400 due one week before course dates
                </div>
                <div className="class-detail-title">
                 Information
                </div>
                <div className="class-cost">
In this rigorous 1 day course, you will learn COSMETINK®'s Signature FEATHER NANO technique, including in-depth pattern-making as well as complex needle theory.  You will use COSMETINK's proprietary and exclusive INKBRUSH™ machine ($399 value), and you will explore our DETAILED DEFINITION™ Needles and our POWDERY PIXEL™ Needles to improve upon and perfect your work.

<br/><br/>
Our Modern Art of Permanent Cosmetics "Advanced" training classes are the best choice for current artists looking to improve their knowledge in the cosmetic tattoo industry and level up on their techniques. 
<br/><br/>
You will explore different needles types and perform the FEATHER NANO technique on proprietary latex with the COSMETINK strokes pattern. You will also learn the correct way to hold your machine for proper pigment implantation, finding your personal hand speed to create your own unique beautiful work, and develop your specific style of work.
<br/><br/>
Our master artist will perform a full procedure (start to finish in detail) on a DEMO MODEL for you to watch and take notes.  Students will see the workflow of our master artists and will be able to ask questions to improve upon their client workflow.
<br/><br/>
Students have an option to add a Model Day onto their course, where they will perform a full FEATHER NANO PROCEDURE on a model.

<div className="paragraph-title">
                INSTILLING CONFIDENCE
</div>
Click on the video to watch our students in action demonstrating how this class gave them the confidence to do their best work.
<div className='c'>
<br/>
        <a alt="learn permanent makeup" href="https://cosacademyvideos.s3.us-east-2.amazonaws.com/confidentvideo.mp4">
        <picture>
        <source className='video-thumb' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.webp" />
        <img className='video-thumb' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.jpg" />
        </picture>
        <div className='play-button'>
        <i class="fa-duotone fa-play"></i>
        </div>
        </a>


</div>
<br/><br/>
Students will receive a professional kit of the highest quality permanent makeup products, including our revolutionary COSMETINK® INKBRUSH™ machine. Your kit includes Master Series supplies that will help you level up your career in the Permanent Makeup Industry.
</div>
               <div className="paragraph-title">
                MASTER KIT INCLUDED
                </div>

                <div className="class-detail-title">
                What's In My Kit?
                </div>
                <div className='class-cost'>
                Your kit is valued at $900 and includes the following EXCLUSIVE COSMETINK® supplies: <br/>
                <br/>○ INKBRUSH™ machine ($399 value)
                <br/>○ 1 box of our DETAILED DEFINITION™ Needles ($39 value)
                <br/>○ 1 box of our POWDERY PIXEL™ Needles ($32 value)
                <br/>○ PERFECT BROWS™ Mapping Stickers
                <br/>○ Brow mapping supplies (including our PERFECT BROWS™ mapping string, mapping pencils, concealer brush)
                <br/><br/>other supplies includes: <br/><br/>
                 Set of Master Series eyebrow pigments
            </div>
                 
                <ProductDrop />


                </div>
                  <br/>
                  <div className="paragraph-title c">
                 CLASS CURRICULUM
                </div>
                <div className="curriculum-area">
      


  <div className="row row-a">
    Feather Nano Brows: Master Series: 1 Day Advanced Training
    </div>

    <div className="row row-b">
    Option to participate in Live Model Day, following Intruction Day
    </div>
    <div className="row row-a">
    Master Series Kit with new tools to level up your work (includes our Signature InkBrush™ PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Advanced Skin & Depth for Permanent Makeup
    </div>
    <div className="row row-a">
    Advanced Color Theory & Proper Pigmentation
    </div>
    <div className="row row-b">
    Overview of tools and supplies
    </div>

    <div className="row row-a">
    COSMETINK®'s Signature Machine Technique and a focus
on creating brows with Beautiful Definition as well as Realistic Hairstrokes.

    </div>
    <div className="row row-b">
    Demo Work by Master Trainers & Hands-on Latex Practice with Individualized Guidance
    </div>
    <div className="row row-a">
    Optional: You will work on a Live Model under the guidance and supervision of our Master Trainers.
    </div>
    <div className="row row-b">
    Certification as a COSMETINK® Pro Artist in Feather Nano Brows<br/><br/>Clients and potential employers know the reputation of our Academy and success rate of our students.  
    By entering the industry as a COSMETINK® Pro Artist in Feather Nano Brows, you are going to stand out as a Top Artist.
    </div>
    </div>

<div className='details width-90c'>
<div className="class-detail-title ">
                  Important Details
                </div>
     
                <div className='class-cost'>
                  
                <br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day if you will be doing the optional Model Day Add-On.  This will be important for you to be able to do your absolute best work on your live model the following day.

                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous permanent makeup done on the area of focus.  Please be sure to choose someone who will be patient, understanding, and comfortable with you. Models must be 18 years or older.
                </div>


                </div>
        

                <div className="div-spacer"></div>
  
  </div>
    )
  }
  
  
  
  if (courseType && courseType === "lash-enhancement") {
    return (
      <div className="individual-course-page desktop-view">
      <div className="course-wrap-inside">
  
      <div className="mobile-banner">
      <video loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.PNG" src="" />
  <div className="mobile-banner-overlay">
  </div>
  <div className='course-title-top'>
    Lash Enhancement (Eyeliner)
  </div>
  <div className="remaining-title">
    1 Day Advanced Training <br/>
    <span className="italic smaller wht">for Artists with Machine Experience</span>
  </div>
  </div>
  </div>
  <br/>
  
  
  
  
  
  <div className='photo-wrap'>
  
  
  
  
  <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.PNG" />
                </picture>
                </div>
  </div>
  
  
  <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title pad-l-10">
  Upcoming Class Dates
  </div>
  
  
               
  
                  {courses ? Object.keys(courses).map((key) => {
            
              
            if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
              
  
              return (
                <div className="course-wrap">
                  
              {!courses[key].sold ? courses[key].upcoming ? 
              <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
                Email Us To Join Waitlist
              </a> :
              <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
              Enroll Now
              </a>
              :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
              <div className="enroll-text">Sold Out</div>
              </a>}
              
              <div className="gray-wrap">
                  <div className="upcoming">
                  Upcoming Class - {courses[key].location}
                  </div>
                    <div className="month">
                    {courses[key].dates.split(" ")[0]}
                    </div>
                    <div className="year">
                    {
                    courses[key].dates.split(" ").slice(1,10).join(" ")
                    }
                    </div>
                  </div></div>)}}) : null }
  
                  <div className='bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10'>
             
             HOW TO BECOME CERTIFIED
                 <div className='class-cost'>
                 ✓ Obtain / Submit a Bloodborne Pathogen Training For Body Art Certificate from ProTrainings<br/>
                 ✓ Attend the 1-Day In-Person Training at COSMETINK Academy<br/>
                 ✓ Satisfactorily Complete Live Model with Supervision (In Class)<br/>
            
                   </div>
                   </div>
  
  
                  <div className={"width-90c"}>
                  <div className="paragraph-title">
                  DETAILS
                  </div>
                  <div className="class-detail-title">
                    Total Cost
                  </div>
                  <div className="class-cost">
                    $1500 (includes kit with necessary lash enhancement supplies | does not include machine)

                  </div>
                  <div className="class-detail-title">
                    Registration Cost
                  </div>
                  <div className="class-cost">
                    $600 non-refundable deposit 
                    <br/>
                    Remaining balance of $900 due one week before course dates
                  </div>
                  <div className="class-detail-title">
                   Information
                  </div>
                  <div className="class-cost">
  In this rigorous 1 day course, you will learn COSMETINK®'s Signature Lash Enhancement technique from start to finish.
  

  <div className="paragraph-title">
                  INSTILLING CONFIDENCE
  </div>
  Click on the video to watch our students in action demonstrating how this class gave them the confidence to do their best work.
  <div className='c'>
  <br/>
          <a alt="learn permanent makeup" href="https://cosacademyvideos.s3.us-east-2.amazonaws.com/confidentvideo.mp4">
          <picture>
          <source className='video-thumb' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.webp" />
          <img className='video-thumb' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.jpg" />
          </picture>
          <div className='play-button'>
          <i class="fa-duotone fa-play"></i>
          </div>
          </a>
  
  
  </div>
  <br/><br/>
  Students will receive a professional kit of the highest quality permanent makeup products, including our revolutionary COSMETINK® POWDERY PIXEL™ needle cartridges. Your kit includes supplies needed to perform our signature lash enhancement technique. 
  </div>
                 <div className="paragraph-title">
                  KIT INCLUDED
                  </div>
  
                  <div className="class-detail-title">
                  What's In My Kit?
                  </div>
                  <div className='class-cost'>
                  Your kit includes the following EXCLUSIVE COSMETINK® supplies: <br/>
                  <br/>○ 1 box of our POWDERY PIXEL™ Needles ($32 value)
                  <br/>○ 15% off the purchase of COSMETINK's INKBRUSH™ machine
                  <br/><br/>other supplies includes: <br/><br/>
                   ink cups, practice latex with pre-drawn lash outlines, eyeliner pigments, cotton rounds, numbing gel
              </div>
                   
          
  
  
                  </div>
  
    <div className="div-spacer"></div>
</div>
  )
}


if (courseType && courseType === "microblading") {
  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">
    <video loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/MTVideo+4.MP4`} poster={`https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_1055.jpg`}/>
                        <div className="mobile-banner-overlay">
                        </div>
                      
                        <div className='course-title-top'>
                  Microblading
                </div>
                        <div className="remaining-title">
                        <br/><br/>
                        2 Day Fundamental Training <br/>
                        <span className="italic smaller wht">No Experience Required | No Cosmetology License Needed</span>
                        </div>
                </div></div>

               
                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            Enroll Now
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <div className="div-spacer"></div>
            <div className="course-wrap nomargtop">

            <div className={"closed-container"} onClick={() => setA()}>
    {aOpen ? "Less Details" : "Tell Me More"}
    <span className="plus">{mOpen ? "-" : "+"}</span>
  </div>
  <div className={aOpen? "details" : "hidden"}>

<div className="class-cost">
In this class, you will learn COSMETINK®'s Signature Microblading technique from start to finish, ensuring to propel you into the industry as a top permanent makeup artist.

<br/><br/>
Our Modern Art of Permanent Cosmetics "Fundamentals" training classes are the best choice for both beginners and those looking to improve their knowledge in the cosmetic tattoo industry. 
<br/><br/>
This in-depth instruction includes the correct way to hold and maneuver your microblade, mastering the proper technique for creating precise, crisp, natural looking, beautifully-curved hairstrokes.  We will help you to create your own unique beautiful work, while going in-depth into color theory, skin anatomy, and more. 
<br/><br/>We will personally guide you through every aspect of permanent makeup, including theory instruction & hands on experience working on live models. <br/>
<br/>
You will learn how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.<br/><br/>

Students leave this class confident in their abilities and ready to put their new knowledge into practice.
<br/><br/>
Students will receive a professional kit of the highest quality permanent makeup products. Your kit includes all supplies needed to perform our signature techniques and get started in your new career. 
</div>
</div>

  <div className={"closed-container"} onClick={() => setM()}>
    {mOpen ? "Less Details" : "Cost & Registration Information"}
    <span className="plus">{mOpen ? "-" : "+"}</span>
  </div>

  <div className={mOpen? "details" : "hidden"}>
  <div className="class-detail-title">
  Total Cost
                </div>
                <div className="class-cost">
                  $4000 (includes full kit worth $700 value)
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $600 non-refundable deposit
                  <br/><br/>
                  Remaining balance of $3400 due one week before course dates
                </div>

                <div className="class-detail-title">
                  Important Details
                </div>
     
                <div className='class-cost'>
                This will consist of an online AND in-person component.  Your login for the online portion will be sent to you two weeks prior to the course dates, and will require approximately 5-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
               
               <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.
              
               <br/><br/>All necessary supplies and tools needed will be provided.  Your supplies will also be enough to get started on your first few clients.  You will receive a supply list that includes everything you need in order to remain stocked up.

                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous permanent makeup done on the area of focus.  Please be sure to choose someone who will be patient, understanding, and comfortable with you. Models must be 18 years or older.
                </div>
                </div>


  <div className={"closed-container"} onClick={() => setS()}>
    {sOpen ? "Less Details" : "Class Curriculum"}
    <span className="plus">{sOpen? "-" : "+"}</span>
 <div>
 </div>
 </div>

  <div className={sOpen? " " : "hidden"}>
  
  <div className="">
  <div className="">



  <div className="row row-a">
    Introduction to Microblading<br/><span className="italic smaller">5 Hours of At-Home Pre-Course Online Work + 2 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    Option to participate in apprenticeship program: complete 5 live models in our facility within 6 months of course completion.  You will earn a 100hr Certification by completing this.
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients
    </div>
    <div className="row row-b">
    Social Media In the Industry
    </div>
    <div className="row row-a">
    Modern Marketing, Pricing Strategies, How to Attract Clients
    </div>
    <div className="row row-b">
    Skin Theory & Depth for Permanent Makeup
    </div>
    <div className="row row-a">
    Color Theory & Proper Pigmentation
    </div>
    <div className="row row-b">
    The Science of Beauty: Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-a">
    COSMETINK®'s Signature Microblading Technique to create Crisp, Precise, Beautifully-curved Hair Strokes.
    <br/><br/>Focus on creating brows with Beautiful Hair Stroke Patterns and Natural Results.

    </div>
    <div className="row row-b">
    Demo Work by Master Trainers & Hands-on Latex Practice with Individualized Guidance
    </div>
    <div className="row row-a">
    You will work on a Live Model under the guidance and supervision of our Master Trainers.
    </div>
    <div className="row row-b">
    Certification as a COSMETINK® PRO Artist<br/><br/>Clients and potential employers know the reputation of our Academy and success rate of our students.  
    By entering the industry as a COSMETINK® Pro Artist, you are going to stand out as a Top Artist.
    </div>
    </div>
    </div></div>     </div> 
        
            
            
            
      
                


    <div className="div-spacer"></div>
  
</div>
  )
}


if (courseType && courseType === "full-fundamentals") {
  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">

      <div className='mobile-banner-left-top'>
        <picture>
        <source className='navbar-aam l' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.webp" />
        <img className='navbar-aam l' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
        </picture>
    
        <div className='bottom-navbar-btn navbar-button-box-shadow platinum platinum-border'>
          100 HOUR FUNDAMENTALS COURSE
        </div>
        </div>
        <video poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnposter.webp" loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyvideos.s3.us-east-2.amazonaws.com/aamvideo.MP4`}/>


                <div className="mobile-banner-overlay">
                </div>
                <div className='course-title-top'>
                  Platinum AAM 100-HR Permanent Cosmetics Fundamental Course
                </div>
                <div className="remaining-title">
                  6 Day Fundamental Training <br/>
                  <span className="italic smaller wht">with Optional Apprenticeship</span>
                </div>
                </div>
                </div>
                <br/>  

                
                <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10">
                Techniques Learned
          
                <div className='class-cost'>
                Feather Nano Brows, Powder Ombré Brows, Watercolor Lip Blush, Lash Enhancement (Eyeliner)
                </div>
                
                </div>
              
          

            
                <div className='photo-wrap'>


                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
                </picture>
                </div>
                
                </div>

          
      
                <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title pad-l-10">
                Upcoming Class Dates
                </div>
                
                
                

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "Bethlehem, Pennsylvania") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            {courses[key].bookNow}
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <br/>




                <div className='bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title pad-l-10'>
                <picture>
        <source className='navbar-aam l' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.webp" />
        <img className='navbar-aam l' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
        </picture><br/>
          HOW TO BECOME AAM CERTIFIED <br/>(Optional, Only required if seeking NJ Accreditation)
              <div className='class-cost'>
              ✓ Complete prep work 2 weeks prior to class & obtain a Bloodborne Pathogen Training for Body Art Certificate from ProTrainings<br/>
              ✓ Attend the 6-Day In-Person Training at COSMETINK Academy<br/>
              ✓ Satisfactorily complete 3 Live Models (in class)<br/>
              ✓ Plan and Complete 12 Additional Models after graduation<br/>
              ✓ Take and Pass the AAM Board Exam<br/>
                </div>
                
        </div>


                <div className={"width-90c"}>
                <div className="paragraph-title">
                DETAILS
                </div>
                <div className="class-detail-title">
                  Total Cost
                </div>
                <div className="class-cost">
                  $7500 (includes full kit worth over $900 value)<br/>+ optional 12 additional apprenticeship models (only required for NJ) - $150 each (due at the time of session)<br/>
                  Optional AAM Board Exam - $397 paid directly to AAM Board
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $1250 non-refundable deposit 
                  <br/> 
                  Remaining balance of $6250 due one week before course dates
                  
                </div>
                <div className="class-detail-title">
                 Information
                </div>
                <div className="class-cost">
In this rigorous, multi-day course, you will learn COSMETINK®'s Signature FEATHER NANO, OMBRÉ BROW, WATERCOLOR LIP BLUSHING, & Lash Enhancement techniques from start to finish, ensuring to propel you into the industry as a top permanent makeup artist in the industry.

<br/><br/>
Our Modern Art of Permanent Cosmetics "Fundamentals" training classes are the best choice for both beginners and those looking to improve their knowledge in the cosmetic tattoo industry. 
<br/><br/>
This in-depth instruction includes the correct way to hold your machine for proper pigment implantation, mastering the pendulum motion, finding your personal hand speed to create your own unique beautiful work, color theory, skin anatomy, and more. 
<br/><br/>We will personally guide you through every aspect of permanent makeup, including theory instruction & hands on experience working on live models. <br/>
<br/>
You will learn how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.<br/><br/>

Students leave this class confident in their abilities and ready to put their new knowledge into practice.

<div className="paragraph-title">
                INSTILLING CONFIDENCE
</div>
Click on the video to watch our students in action demonstrating how this class gave them the confidence to do their best work.
<div className='c'>
<br/>
        <a alt="learn permanent makeup" href="https://cosacademyvideos.s3.us-east-2.amazonaws.com/confidentvideo.mp4">
        <picture>
        <source className='video-thumb' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.webp" />
        <img className='video-thumb' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/confidence.jpg" />
        </picture>
        <div className='play-button'>
        <i class="fa-duotone fa-play"></i>
        </div>
        </a>


</div>
<br/><br/>
Students will receive a professional kit of the highest quality permanent makeup products, including our revolutionary COSMETINK® INKBRUSH™ machine. Your kit includes all supplies needed to perform our signature techniques and get started in your new career. 
</div>
               <div className="paragraph-title">
                FULL KIT INCLUDED
                </div>

                <div className="class-detail-title">
                What's In My Kit?
                </div>
                <div className='class-cost'>
                Your kit is valued at $900 and includes the following EXCLUSIVE COSMETINK® supplies: <br/>
                <br/>○ INKBRUSH™ machine ($399 value)
                <br/>○ 2 boxes of our DETAILED DEFINITION™ Needles ($39 value)
                <br/>○ 1 box of our POWDERY PIXEL™ Needles ($32 value)
                <br/>○ PERFECT BROWS™ Mapping Stickers
                <br/>○ Brow mapping supplies (including our PERFECT BROWS™ mapping string, mapping pencils, concealer brush)
                <br/><br/>other supplies includes: <br/><br/>
                brow razors, ink cups, practice latex with pre-drawn brow and lip outlines, 3D lip latex molds, and stroke patterns, lip wands, numbing cream, machine grip tape, clip cord sleeve covers, machine power supply, and full sets of eyebrow and lip pigments
            </div>
                 
                <ProductDrop />


                </div>
                <br/>
                  <div className="paragraph-title c">
                 CLASS CURRICULUM
                </div>
                <div className="curriculum-area">
      

                <div className="row row-a">
    Feather Nano / Powder Ombré Brows, Watercolor Lips & Lash Enhancement<br/><span className="italic smaller">5 Hours of At-Home Pre-Course Online Work + 4 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    100 hr Certification after completing the course + apprenticeship
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes our Signature InkBrush™ PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Social Media In the Industry
    </div>
    <div className="row row-a">
    Modern Marketing, Pricing Strategies, How to Attract Clients
    </div>
    <div className="row row-b">
    Skin Theory & Depth for Permanent Makeup
    </div>
    <div className="row row-a">
    Color Theory & Proper Pigmentation
    </div>
    <div className="row row-b">
    The Science of Beauty: Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-a">
    COSMETINK®'s Signature Machine Technique to create Powdery Brows and Luscious, Watercolor Lips. 
    <br/><br/>Focus on creating brows with Beautiful Gradients & Definition and Lips with Plush Color and Defined Cupid's Bows.

    </div>
    <div className="row row-b">
    Hands-on Latex Practice with Individualized Guidance
    </div>
    <div className="row row-a">
    You will work on 3 Live Models under the guidance and supervision of our Master Trainers.<br/>
    <br/>One Brow Model; One Lip Model; One Lash Enhancement Model
    </div>
    <div className="row row-b">
    Certification as a COSMETINK® PRO Artist<br/><br/>Clients and potential employers know the reputation of our Academy and success rate of our students.  
    By entering the industry as a COSMETINK® Pro Artist, you are going to stand out as a Top Artist.
    </div>
    </div>



    <div className='details width-90c'>
<div className="class-detail-title ">
                  Important Details
                </div>
     
                <div className='class-cost'>
                  
                This will consist of an at-home AND in-person component. At-home preparation work will be sent to you two weeks prior to the course dates, and will require approximately 5-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
               
                <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.

                <br/><br/>*You are responsible for providing models for your live model work.  Models must not have had previous permanent makeup done on the area of focus.  Please be sure to choose someone who will be patient, understanding, and comfortable with you. Models must be 18 years or older.
                </div>


           </div></div>

        
            
                
      
                


  
  
  )
}








    return (
      <></>
    )

    ;
    }