import { combineReducers } from 'redux';
import session from './session_reducer';
import errors from './errors_reducer';
import user from './users_reducer';
import checkout from './checkout_reducer'
import courses from './course_reducer'

const RootReducer = combineReducers({
  errors,
  session,
  user,
  checkout,
  courses
});

export default RootReducer;