
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";


export default function Gallery(props) {



    const [open, setOpen] = useState(null)

  
    function setOpenSection(num) {
      if (open === num) {
        setOpen(null)
      } else {
        setOpen(num)
      }
    }
  
    useEffect(() => {
       window.scrollTo(0, 0)
       document.querySelector('video').playbackRate = .3;
        }, [])



 
  

    return (
      <div className="courses-page">

        <video className='highlight' loop muted autoPlay src="https://cosacademyvideos.s3.us-east-2.amazonaws.com/acadNikky1.mp4"></video>
        <video className='highlight' loop muted autoPlay src="https://cosacademyvideos.s3.us-east-2.amazonaws.com/acadvid24.mp4"></video>

                  <img className="full-op" src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acad2.JPG" alt="permanent makeup training microblading training learn microblading learn permanent makeup permanent makeup courses"/>
                  <img className="full-op" src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acad3.JPG" alt="permanent makeup training microblading training learn microblading learn permanent makeup permanent makeup courses"/>
                  <img className="" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/A45DE3F5-3E80-409F-AB6B-3CB3C57895F7.jpeg`}/>
                  <img className="full-op" src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acad4.JPG" alt="permanent makeup training microblading training learn microblading learn permanent makeup permanent makeup courses"/>

                  <img className="full-op" src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acad6.JPG" alt="permanent makeup training microblading training learn microblading learn permanent makeup permanent makeup courses"/>
                  <img className="full-op" src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acad7.JPG" alt="permanent makeup training microblading training learn microblading learn permanent makeup permanent makeup courses"/>
         </div>
    );
    }