import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";


export default function Financing(props) {



    const [open, setOpen] = useState(null)

  
    function setOpenSection(num) {
      if (open === num) {
        setOpen(null)
      } else {
        setOpen(num)
      }
    }
  
    useEffect(() => {
      window.scrollTo(0, 0)
    
    }, [])




 
  

    return (
      <>






        


         <div className="courses-page">
         <div className="">
        <div className="">

        <div className="section relative">
       
     

                  

                            <div className="section">
  <br/>
  <div className="entry-text-low uppercase ">
  <div className="">
  <div className="uppercase f-20 black-friday-special-2 uppercase small-c c">Financing<br/>Available</div></div>
  <br/>

    <div className="uppercase smaller width-90" >
    Talk with us today to begin exploring loan options for your course amount.<br/>
    <br/>We can help you explore different options to find what works best for you.<br/><br/>By applying for financing, your credit will not be affected.<br/><br/>
    Our hours of operation are Tuesday through Friday from 10am - 5pm, so we will respond during these hours, and we always do our best to get back to you within 24-48 hours.



    </div>
<br/>
  
  </div>
  </div>



      </div>   </div>

      


</div><div className="email-financing">

<div className="uppercase pad-btw smaller" >
</div> 
  <a href="mailto:cosmetinkbeauty@gmail.com?subject=COSMETINK ACADEMY FINANCING&body=Please send me a credit application to see how much I'm approved for.">
  Email us to discuss your financing options
  </a>
</div></div>

    



          
         


         </>
    );
    }