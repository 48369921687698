import { RECEIVE_AVAILABLE_COURSES, RECEIVE_USER_COURSES, RECEIVE_NEW_COURSE,
         RECEIVE_COURSE_SECTIONS, 
         RECEIVE_CHAPTERS,
         RECEIVE_ASSIGNMENTS} from '../actions/course_actions';
  
  const CourseReducer = (state = { availableCourses: undefined, userCourses: undefined, newCourse: undefined,
                        sections: undefined, chapters: undefined, assignments: undefined}, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_AVAILABLE_COURSES:
        newState.availableCourses = action.courses.data;
        return newState;
      case RECEIVE_USER_COURSES:
        newState.userCourses = action.usercourses.data;
        return newState;
      case RECEIVE_NEW_COURSE:
        newState.newCourse = action.newCourse.data;
        return newState;
      case RECEIVE_COURSE_SECTIONS:
        newState.sections = action.sections.data;
        return newState;
      case RECEIVE_CHAPTERS:
        newState.chapters = action.chapters.data;
        return newState;
      case RECEIVE_ASSIGNMENTS:
        newState.assignments = action.assignments.data;  
        return newState;
      default:
        return state;
    }
  };
  
  export default CourseReducer;