
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";


export default function FAQs(props) {



    const [open, setOpen] = useState(null)
    const [question, setQuestion] = useState(null)


    function setOpenSection(num) {
            if (open === num) {
              setOpen(null)
            } else {
              setOpen(num)
            }
          }
      
    function setQuestionNum(num) {
             if (question === num) {
                setQuestion(null)
             } else {
                setQuestion(num)
             }
            
          }

          useEffect(() => {
            window.scrollTo(0, 0)
             }, [])
     
      
              return (
                  <div className="individual-courses-page">
                  <div className="">
                  <div className="">
                  <div className="fade-in">
                      <div className='left-half less-bottom'>
                  <img src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_3940+2.jpg" className="faq-img fade-in "></img>
                  </div>
          
                  <div className="width-60 right-half moveup">
      
                 <div className="c smaller-bf">
                     FAQs
                 </div>
                  <div className="">
                  <div className={"entry-text-low smaller trans-back justify fit-content"}>
      
          
                  <div className="smaller font-light trans-back bold"></div>
                  <br/>
                  <div className="question-wrap trans-back" onClick={() => setQuestionNum(1)}>
                  <div className="smaller font-light trans-back bold">What can I do with my certification?<span className="right-angle trans-back">{question === 1 ? `-` : `+` }</span></div>
                  <br/>
                  <div className={question === 1 ? "trans-back" : "hidden trans-back"}>
                   Our certifications allow you to begin working on clients in the field, whether you want to work for someone or start your own business.
                  
                  <br/><br/>
                   </div>
                   </div>
                   <br/>
                   <div className="question-wrap trans-back" onClick={() => setQuestionNum(2)}>
                   <div className="smaller font-light trans-back bold">What certifications are given?<span className="right-angle trans-back">{question === 2 ? `-` : `+` }</span></div>
                  <br/>
                  <div className={question === 2 ? "trans-back" : "hidden trans-back"}>
                  You will become certified as a COSMETINK artist in your chosen technique.
      
                  Upon completion of our in-person courses, we will grant you a certification of completion.  You can begin working with clients right away.  We offer the opportunity to complete a 100-hr certification by completing our apprenticeship program.
                   <br/><br/>
                   </div>
                   </div>
                   <br/>
                   <div className="question-wrap trans-back" onClick={() => setQuestionNum(3)}>
                   <div className="smaller font-light trans-back bold">Do I Need a Cosmetology License?<span className="right-angle trans-back">{question === 3 ? `-` : `+` }</span></div>
                  <br/>
                  <div className={question === 3 ? "trans-back" : "hidden trans-back"}>
                   <div className="trans-back">Although we recommend having some prior makeup experience or interest in beauty, a cosmetology license is not a pre-requisitive for Permanent Cosmetics courses or work.<br/>
                   <br/>Our students come from all career backgrounds, and we welcome you regardless of your background.
                   </div>
                   <br/><br/>
                   </div>
                   </div>
                   <br/>
                   <div className="question-wrap trans-back" onClick={() => setQuestionNum(4)}>
                   <div className="smaller font-light trans-back bold">How much does it cost?<span className="right-angle trans-back">{question === 4 ? `-` : `+` }</span></div>
                  <br/>
                  <div className={question === 4 ? "trans-back" : "hidden trans-back"}>
                   <div className="trans-back"> Courses range in price from $3000 to $7500 USD.  Our price includes a pre-course preparation program, in-person training days where you will work on a live model, and lifetime support.  If you attend a fundamental class in our Bethlehem, PA, location, you will also have 6 months of on-going support through the apprenticeship option.  Students wanting to work in New Jersey must complete the AAM 100-hr Platinum Course and Apprenticeship.
                   </div>
                   <br/><br/>
                   </div>
                   </div>
                   <br/>
      
      
                   <div className="question-wrap trans-back" onClick={() => setQuestionNum(5)}>
                   <div className="smaller font-light trans-back bold">How do I prepare?<span className="right-angle trans-back">{question === 5 ? `-` : `+` }</span></div>
      
                   <br/>
                   <div className={question === 5 ? "trans-back" : "hidden trans-back"}>
                   <div className="trans-back">
                       Once you register for the course, you will be put into our online system.  Two weeks prior to your course dates (if you're taking an introductory course), you will receive prep work information for your at-home work.  This will get you ready for in-person practice.
                   </div>
                   <br/><br/>
                   </div>
                   </div>
                  <br/>
                   <div className="question-wrap trans-back" onClick={() => setQuestionNum(6)}>
                   <div className="smaller font-light trans-back bold">How Do I Register?<span className="right-angle trans-back">{question === 6 ? `-` : `+` }</span></div>
                   <br/>
                   <div className={question === 6 ? "trans-back" : "hidden trans-back"}>
                   Navigate to <Link to={`/home`}>our courses page</Link>. There, you can choose your course and register directly online.  Registration requires a non-refundable deposit to hold your place in class.  This goes towards the final class balance.
                   <br/><br/>
                   </div>
                   </div>
                      <br/>
                      <div className="question-wrap trans-back" onClick={() => setQuestionNum(7)}>
                   <div className="smaller font-light trans-back bold">How do I Make Payment?<span className="right-angle trans-back">{question === 7 ? `-` : `+` }</span></div>
                  <br/>
                  <div className={question === 7 ? "trans-back" : "hidden trans-back"}>
                      We take cash, credit, and debit for your final course balance.  Payment is taken one week prior to your course dates.
                      <br/><br/>
              
          
                   </div>
      
      
      
      
                   
                   </div>
                   
      
             
                      <br/>
                      <div className="question-wrap trans-back" onClick={() => setQuestionNum(8)}>
                   <div className="smaller font-light trans-back bold">Do you offer financing?<span className="right-angle trans-back">{question === 8 ? `-` : `+` }</span></div>
                  <br/>
                  <div className={question === 8 ? "trans-back" : "hidden trans-back"}>
                      We have a finance partner that offers financing for some applicants.  If you need assistance with paying for your course, please reach out to us.
                   
              <br/><br/>
          
                   </div>
                   </div>
                   </div>
                 
                      </div>
        
                     </div>
                     </div>
                      </div>
      
               
                 </div>
      
      </div>
                      
                      
              )
          }