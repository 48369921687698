import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet' 

export default function AllCourses(props) {

  const [open, setOpen] = useState(null)
  const [description, setDescription] = useState(null)
  const [register, setRegister] = useState(false)
  const [mOpen, setmOpen] = useState(false)
  const [sOpen, setsOpen] = useState(false)
  const [courseDetail, setCourseDetail] = useState(null)
  const [courseType, setCourseType] = useState(null)
  const [pos, setPos] = useState(false)

  const [showAAM, setShowAAM] = useState(false)
  const [showFundamentals, setShowFundamentals] = useState(false)
  const [showMasters, setShowMasters] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  
  }, [])




  const { fetchAvailableCourses,
    courses, type, key
   } = props


useEffect(() => {
fetchAvailableCourses() 

}, [])




function setAAM() {
  showAAM ? setShowAAM(false) : setShowAAM(true) 
  
}

function setFundamentals() {
  showFundamentals ? setShowFundamentals(false) : setShowFundamentals(true) 
  
}

function setMasters() {
  showMasters ? setShowMasters(false) : setShowMasters(true) 
  
}











    return (
      <>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Permanent Makeup Training Academy | Bethlehem, Easton, Allentown, Philly, New York | Pennsylvania</title> 
                <link rel="canonical" href="https://academy.cosmetink.com/" />
                <meta name="description" content="Beginner Certification Classes - Learn Microblading, Ombre Brows, Lip Blush - NO EXPERIENCE NEEDED - NO ESTHETICIAN LICENSE REQUIRED" />
                <meta name="keywords" content="ombre brows training, permanent makeup training, permanent makeup school, permanent makeup classes beginners, microblading certification, ombre brows certification, lip blush training, lip blush certification, best microblading training, aamp training, beauty school pa, learn microblading, tattoo makeup, cosmetic ink, permanent makeup, microblading, best microblading, semi-permanent makeup, microbladed eyebrows, microblading de cejas" />

          </Helmet>    
          

 

      <div className="courses-page slide-in-bottom">

        <div className='c border-bottom pad-20'>
        <div className='small-menu-btn2'>
        Located in Bethlehem, Pennsylvania, we are on the border of Pennsylvania & New Jersey.  We offer classes for Pennsylvania, New Jersey, and Beyond.<br/><br/>Explore all of our upcoming course offerings below:
        </div>
        </div>

       


      {(showAAM || showMasters) ? null : 
      <>
        <div className='c border-bottom'>
      <div className='bottom-navbar-btn  menu-btn navbar-button-box-shadow '>
          Fundamental Courses
          </div>
          <div className='small-menu-btn'>
          For All States (except New Jersey)
          </div>
       </div>
       <div onClick={setFundamentals} className='c'>
          <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum  pad-no-top">
              {!showFundamentals? "SEE COURSES & UPCOMING DATES" : "Go Back To ALL COURSES"}</div>
              </div><br/>
        
              </>}

    <div className={showFundamentals ? "fade-in" : 'hidden'}>


    <div className="">

    <div className="div-spacer"></div>
<br/>

      
<Link to='/courses/full-fundamentals'>

 <div className="course-program-title poppins-semibold">
  
    Full Fundamentals <br/>
    <span className='course-program-title2'>
    Feather Nano / Powder Ombré Brows, Watercolor Lip Blush & Lash Enhancement (Eyeliner)
    </span>
  </div>
  <div className="course-program-subtitle">

    6 Day Fundamental Training <br/>
    <span className="italic smaller">with Optional Apprenticeship<br/><br/>

    <div className='photo-wrap'>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
</picture>
</div>

<div className='photo-container'>
            <picture>
            <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.webp" />
            <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.PNG" />
            </picture>
            </div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
</picture>
</div>

</div>
    No Experience Needed / No Cosmetology License Required / All Backgrounds Welcome</span>

 </div>
 <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-less-top">
          MORE INFO <span className="arrow">⟶</span></div>

 </Link>
 </div>

 <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title ">
Upcoming Class Dates
</div>


 
            {courses ? Object.keys(courses).map((key) => {
      
        
        if (courses[key].courseType && courses[key].courseType === "full-fundamentals") 
        

        return (
            <>
          <div className="course-wrap">
        {!courses[key].sold ? courses[key].upcoming ? 
        <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
          Email Us To Join Waitlist
        </a> :
        <>
        <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
          {courses[key].bookNow}
        </a></>
        :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
        <div className="enroll-text">Sold Out</div>
        </a>}
        

        <div className="gray-wrap list">
            <div className="upcoming">
            {courses[key].location}
            </div>
              <div className="month">
              {courses[key].dates.split(" ")[0]}
              </div>
              <div className="year">
              {
              courses[key].dates.split(" ").slice(1,10).join(" ")
              }
              </div>
            </div></div></>)}) : null }


            <div className="div-spacer"></div>
<br/>
    <div className="">

      
    <Link to='/courses/ombre-lips'>

     <div className="course-program-title2 pad-l-10 ">
      
        Feather Nano / Powder Ombré Brows + Watercolor Lip Blush
      </div>
      <div className="course-program-subtitle">
   
        5 Day Fundamental Training <br/>
        <span className="italic smaller">with Optional Apprenticeship<br/><br/>

        <div className='photo-wrap'>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
</picture>
</div>

<div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.PNG" />
                </picture>
                </div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
</picture>
</div>

</div>
        No Experience Needed / No Cosmetology License Required / All Backgrounds Welcome</span>

     </div>
     <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum  pad-less-top">
              MORE INFO <span className="arrow">⟶</span></div>

     </Link>
     </div>

     <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title ">
Upcoming Class Dates
</div>


     
                {courses ? Object.keys(courses).map((key) => {
          
            
            if (courses[key].courseType && courses[key].courseType === "ombre-lips") 
            

            return (
                <>
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <>
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
              {courses[key].bookNow}
            </a></>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            

            <div className="gray-wrap list">
                <div className="upcoming">
                {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div></>)}) : null }

                
                
<div className="div-spacer"></div>
<br/>
<div className="">
    <Link to='/courses/ombre'>

     <div className="course-program-title2 pad-l-10 ">
      
        Feather Nano / Powder Ombré Brows 
      </div>
      <div className="course-program-subtitle">
   
        3 Day Fundamental Training <br/>
        <span className="italic smaller">with Optional Apprenticeship<br/><br/>

       
        <div className='photo-wrap'>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
</picture>
</div>

<div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.PNG" />
                </picture>
                </div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
</picture>
</div>

</div>

        No Experience Needed / No Cosmetology License Required / All Backgrounds Welcome</span>

     </div>
     <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum  pad-less-top">
              MORE INFO <span className="arrow">⟶</span></div>

     </Link>
     </div>

     <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title">
Upcoming Class Dates
</div>


     
                {courses ? Object.keys(courses).map((key) => {
          
            
            if (courses[key].courseType && courses[key].courseType === "ombre") 
            

            return (
                <>
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <>
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
              {courses[key].bookNow}
            </a></>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            

            <div className="gray-wrap list">
                <div className="upcoming">
                {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div></>)}) : null }

                

                <div className="div-spacer"></div><br/>
<div className="">
    <Link to='/courses/lips'>

     <div className="course-program-title2 pad-l-10 ">
      
        Watercolor Lip Blush
      </div>
      <div className="course-program-subtitle">
   
        2 Day Fundamental Training <br/>
        <span className="italic smaller">with Optional Apprenticeship<br/><br/>

       
     
<div className='photo-wrap'>




<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
</picture>
</div>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
</picture>
</div>





<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
</picture>
</div>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
</picture>
</div>

</div>

        No Experience Needed / No Cosmetology License Required / All Backgrounds Welcome</span>

     </div>
     <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-less-top">
              MORE INFO <span className="arrow">⟶</span></div>

     </Link>
     </div>

     <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title">
Upcoming Class Dates
</div>


     
                {courses ? Object.keys(courses).map((key) => {
          
            
            if (courses[key].courseType && courses[key].courseType === "lips") 
            

            return (
                <>
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <>
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
              {courses[key].bookNow}
            </a></>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            

            <div className="gray-wrap list">
                <div className="upcoming">
                {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div></>)}) : null }




                
              
                </div>
            
            

          {(showAAM || showFundamentals) ? null : <>
      
               <div className='c border-bottom'>
               <div className='bottom-navbar-btn navbar-button-box-shadow gold'>
          MASTER COURSES</div>
          <div className='small-menu-btn'>
          For Experienced Artists
          </div>
          <div onClick={setMasters} className='c'>
          <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-no-top">
              {!showMasters? "SEE COURSES & UPCOMING DATES" : "GO BACK TO ALL COURSES"}</div>
              </div><br/>
       </div>
       </>}
    
    <div className={showMasters? "fade-in" : 'hidden'}>

    <div className="div-spacer"></div>
<br/>
<div className="">
    <Link to='/courses/advanced-brows'>

     <div className="course-program-title2 pad-l-10 ">
      
        Feather Nano Brows: Master Series
      </div>
      <div className="course-program-subtitle">
   
        1 Day Advanced Training<br/>
        <span className="italic smaller">with Optional Supervised Model Day Add-On | Machine Experience Required<br/><br/>

        </span>
       
<div className='photo-wrap'>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
</picture>
</div>

</div>


     </div>
     <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum  pad-less-top">
              MORE INFO <span className="arrow">⟶</span></div>

     </Link>
     </div>

     <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title ">
Upcoming Class Dates
</div>


     
                {courses ? Object.keys(courses).map((key) => {
          
            
            if (courses[key].courseType && courses[key].courseType === "advanced-brows") 
            

            return (
                <>
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <>
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
              {courses[key].bookNow}
            </a></>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            

            <div className="gray-wrap list">
                <div className="upcoming">
                {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div></>)}) : null }

                


                <div className="div-spacer"></div>
                <br/>
                <div className="">
    <Link to='/courses/lash-enhancement'>

     <div className="course-program-title2 pad-l-10 ">
      
        Lash Enhancement (Eyeliner)
      </div>
      <div className="course-program-subtitle">
   
        1 Day Advanced Training<br/>
        <span className="italic smaller">Machine Experience Required<br/><br/></span>

<div className='photo-wrap'>
<div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.PNG" />
                </picture>
                </div>


</div>

       

     </div>
     <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-less-top">
              MORE INFO <span className="arrow">⟶</span></div>

     </Link>
     </div>

     <div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title ">
Upcoming Class Dates
</div>


     
                {courses ? Object.keys(courses).map((key) => {
          
            
            if (courses[key].courseType && courses[key].courseType === "lash-enhancement") 
            

            return (
                <>
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <>
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
              {courses[key].bookNow}
            </a></>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            

            <div className="gray-wrap list">
                <div className="upcoming">
                {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div></>)}) : null }
                <div className="div-spacer"></div>
                    </div>


       {(showFundamentals || showMasters) ? null : 
       <>
        <picture>
      <source className='navbar-aam' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.webp" />
      <img className='navbar-aam' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
      </picture>
      <div className='c'>
          <div className='bottom-navbar-btn navbar-button-box-shadow platinum platinum-border'>
          AAM - 100 HOUR FUNDAMENTALS COURSE
          </div>
        
          <div className='small-menu-btn'>
          New Jersey Requirement
          </div>
          </div>
          <div onClick={setAAM} className='c'>
          <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum  pad-no-top">
              {!showAAM ? `${"SEE UPCOMING DATES"}` : "Go Back To All Courses"}</div>
              </div><br/>
          <div className={showAAM ? "fade-in" : 'hidden'}>

   
          <div>

  
          <Link to='/courses/aam-fundamentals'>


<div className="course-program-title poppins-semibold">
  
Platinum AAM 100-HR <br/>
  <span className='course-program-title2'>
 
Permanent Cosmetics Fundamental Course
</span>
</div>

 <div className="course-program-subtitle">

   6 Day Fundamental Training <br/>
   <span className="italic smaller">with Apprenticeship (required for New Jersey)<br/><br/>

   <div className='photo-wrap'>


<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips1.PNG" />
</picture>
</div>

<div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.PNG" />
                </picture>
                </div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadeyeliner.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips6.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips2.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
</picture>
</div>

<div className='photo-container'>
<picture>
<source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.webp" />
<img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/acadlips7.PNG" />
</picture>
</div>

</div>
   No Experience Needed / No Cosmetology License Required / All Backrounds Welcome</span>

</div>
<div className="bottom-navbar-btn navbar-button-box-shadow2 platinum pad-less-top">
         MORE INFO <span className="arrow">⟶</span></div>

</Link>
</div>



<div className="bottom-navbar-btn navbar-button-box-shadow platinum class-detail-title ">
Upcoming Class Dates
</div>
{courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType && courses[key].courseType === "aam-fundamentals") 
          

          return (
              <>
            <div className="course-wrap">
          {!courses[key].sold ? courses[key].upcoming ? 
          <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
            Email Us To Join Waitlist
          </a> :
          <>
          <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            {courses[key].bookNow}
          </a></>
          :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
          <div className="enroll-text">Sold Out</div>
          </a>}
          

          <div className="gray-wrap list">
              <div className="upcoming">
              {courses[key].location}
              </div>
                <div className="month">
                {courses[key].dates.split(" ")[0]}
                </div>
                <div className="year">
                {
                courses[key].dates.split(" ").slice(1,10).join(" ")
                }
                </div>
              </div></div></>)}) : null }
              </div>
              
        </>}
      
        {(showAAM) ? 
        <div className="bottom-navbar-btn navbar-button-box-shadow2 platinum class-detail-title ">
                Techniques Learned
          
                <div className='class-cost'>
                Feather Nano Brows, Powder Ombré Brows, Watercolor Lip Blush, Lash Enhancement (Eyeliner)
                </div>
                
                </div> : null}

            


</div>



</>
    )

    ;
    }