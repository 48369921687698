import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";


export default function Beginner(props) {



    const [open, setOpen] = useState(null)

  
    function setOpenSection(num) {
      if (open === num) {
        setOpen(null)
      } else {
        setOpen(num)
      }
    }
  




 
  

    return (
      <>






        


         <div className="">
         <div className="page">
        <div className="">

        <div className="section">

        <div className="class-sample-row2">
      <div className="class-sample-2">
     <img src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/OmbreBrowsClass.png`}>
     </img>
     </div>
     <div className="class-sample-2">
     <img src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/Microblading.png`}>
     </img>
     </div>

     <div className="class-sample-2">
     <img src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/OandLClass.png`}>
     </img>
     </div>
     </div>
   
</div>
</div>

   
  </div>
    


        
          
          <div className="section4">
 

            <div className="course-container">

        
            <div className="footer">
        <div onClick={() => setOpenSection(1)} className="hidden drop">
        FAQs
        <span className="add">+</span>
        </div>
        <div className={open === 1 ? "drop light" : "hidden"}>
          Frequently asked questions
        </div>

        <div onClick={() => setOpenSection(2)} className="drop">
        Contact
        <span className="add">+</span>
        </div>
        <div className={open === 2 ? "drop light" : "hidden"}>
        Shoot us an email at <a href="mailto:cosmetinkbeauty@gmail.com">cosmetinkbeauty@gmail.com</a>
        </div>

        <div onClick={() => setOpenSection(3)}  className="drop">
        About Us
        <span className="add">+</span>
        </div>
        <div className={open === 3 ? "drop light" : "hidden"}>
      Serving as the premier permanent cosmetics studio in the Lehigh Valley since 2017, COSMETINK has served thousands of happy clients from all over the tristate area including but not limited to NYC, Lehigh Valley PA, Delaware, Maryland, Virgina, and more.  
      <br/><br/>
      With a track record for success, CoFounders Nicolette Altemose and Sean Altemose want to bring their knowledge of the craft to you through Cosmetink Academy.  
      <br/><br/>The Academy was first established in late 2019, and starting in 2021, Nicolette and Sean have a reignited motivation and passion to bring it to more students. 
      
      <br/><br/>Check us out on instagram! <a href="https://www.instagram.com/cosmetinkacademy"><i class="fab fa-instagram"></i> @cosmetinkbeauty</a>
        </div>


        <div onClick={() => setOpenSection(4)} className="drop">
        Our Flagship
        <span className="add">+</span>
        </div>
        <div className={open === 4 ? "drop light" : "hidden"}>
        We are located at 3859 Nazareth Pike, Unit 103, Bethlehem, PA 18020.
        Visit our website <a href="https://www.cosmetink.com">cosmetink.com</a> to see our artists, our services, and get to see a little bit more about our day-to-day work.
        </div>

        <br/>
        <br/>
        <div className="c">
        © 2021 COSMETINK, COSMETINK ACADEMY
        <br/> All rights reserved.
        </div>
        </div>
          </div>
         

          
      
         </div>
         </div>



         </>
    );
    }