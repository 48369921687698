
import './navbar.css'

import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'



export default function BottomNav(props) {

  const [open, setOpen] = useState(null)
  const [mobileNav, setMobileNav] = useState(false)
  const [navblack, setNavblack] = useState(false)
  const [beginner, setBeginner] = useState(false)
  const [advanced, setAdvanced] = useState(false)
  const [showCourses, setShowCourses] = useState(false)

  function openMobileNav() {
    mobileNav ? setMobileNav(false) : setMobileNav(true)
  }

  function setCourses() {
      showCourses ? setShowCourses(false) : setShowCourses(true)
      setBeginner(false)
      setAdvanced(false)
  }

  function setBeginnerUp() {
    beginner ? setBeginner(false) : setBeginner(true)
}

function setAdvancedUp() {
    advanced ? setAdvanced(false) : setAdvanced(true)
}
    return (
      <div className="zindex hidden">


        <div onClick={openMobileNav} className={mobileNav ? "abs-navx" : "abs-nav"}>
            <span className={mobileNav ? "hamburger rotate-45l"
            : "hamburger"}>

            </span>
            {!mobileNav ?
            <span className={"hamburger" }>
            </span> : null }

            <span className={mobileNav ? "hamburger rotate-45r" : "hamburger"}>
                
            </span>
          </div>

          <div className={mobileNav ? "full-mobile-nav slide-in-bottom" : "hidden"}>
              <div className="bottom-nav-box">


                <button onClick={setCourses} className="bnbt uppercase">
                        Programs / Courses
                </button>


            <div onClick={setBeginnerUp} className={showCourses ? "bnb spacers" : "hidden"}>
            FUNDAMENTAL COURSES
            </div>

            <div className={beginner ? "" : "hidden"}>

            <Link onClick={openMobileNav}  to="/courses/microblading">
            <button className="bnb spacers2">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MICROBLADING   <span className="assist ">&nbsp;|&nbsp; 2 DAYS</span>
            </button>
            </Link>
            <Link onClick={openMobileNav}  to="/courses/ombre-lips">
            <button className="bnb spacers2">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OMBRE BROWS & LIP BLUSH   <span className="assist">&nbsp;|&nbsp; 4 DAYS</span>
            </button>
            </Link>
            <Link onClick={openMobileNav} to="/courses/ombre">
            <button className="bnb spacers2">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OMBRE BROWS   <span className="assist ">&nbsp;|&nbsp; 2 DAYS</span>
            </button>
            </Link>
            <Link onClick={openMobileNav}  to="/courses/lips">
            <button className="bnb spacers2">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LIP BLUSH <span className="assist ">&nbsp;|&nbsp; 2 DAYS </span>
            </button>
            </Link>
         

            </div>
            <Link onClick={openMobileNav} to="/history">
            <button className="bnbt uppercase">
                        About Us
              </button>
              </Link>

              <Link onClick={openMobileNav} to="/trainers">
                    <button className="bnbt uppercase">
                        Meet the Instructors
                    </button>
              </Link>
<br/>
        <div className="social-links">
        <a target="_blank" href="https://instagram.com/cosmetinkbeauty">
        <i class="fa-brands fa-instagram"></i></a>
        <a target="_blank" href="https://www.youtube.com/channel/UCHBHoB48jZLahe7xSTfDKMw">
        <i class="fa-brands fa-youtube"></i></a>
        </div>
      
        <div className="bnbt">
        COSMETINK HEADQUARTERS
        </div>
        <div className="bnba spacers">
        <div className="uppercase">
          3859 Nazareth Pike, Unit 103<br/>
          Bethlehem, PA 18020<br/>
          </div>
          <a href="mailto:cosmetinkbeauty@gmail.com">cosmetinkbeauty@gmail.com</a>
        </div>
        <br/>
        <br/>
        <div className="bnba spacers">
        © 2024 COSMETINK, COSMETINK ACADEMY
        <br/> All rights reserved.
        </div>
          </div>              </div>
          <div onClick={openMobileNav} className={mobileNav ? "full-back-nav" : "hidden"}>
          <div onClick={openMobileNav} className={mobileNav ? "abs-navx" : "abs-nav"}>
            <span className={mobileNav ? "hamburger rotate-45l"
            : "hamburger"}>

            </span>
            {!mobileNav ?
            <span className={"hamburger" }>
            </span> : null }

            <span className={mobileNav ? "hamburger rotate-45r" : "hamburger"}>
                
            </span>
          </div>
          </div>

      </div>
        );
    }