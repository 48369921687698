import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'


export default function AAMCourse(props) {
    const [open, setOpen] = useState(null)
    const [description, setDescription] = useState(null)
    const [register, setRegister] = useState(false)
    const [mOpen, setmOpen] = useState(false)
    const [sOpen, setsOpen] = useState(false)
    const [courseDetail, setCourseDetail] = useState(null)
    const [courseType, setCourseType] = useState(null)
 

useEffect(() => {


}, [])


function setM() {
    mOpen ? setmOpen(false) : setmOpen(true) 
    
  }
  
  function setS() {
    sOpen ? setsOpen(false) : setsOpen(true) 
    
  }
  
  function openCourse(num) {
    setCourseDetail(num)
  }
  



  return (
    <div className="individual-course-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">

   
    <video poster="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnposter.webp" loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyvideos.s3.us-east-2.amazonaws.com/feathernano_ombre.mp4`}/>

                <div className="mobile-banner-overlay">
                </div>
              
                <div className='course-title-top'>
                 Feather Nano / Powder Ombré Brows
                </div>
                                        <div className="remaining-title ">
                                          2 Day Fundamental Training <br/>
                                          <span className="italic smaller wht">No Experience Required</span>
                                        </div>
                </div></div>

                <br/>
      




                <div className='photo-wrap'>


                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnwork-sample5.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fncombo.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample1.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample2.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample3.PNG" />
                </picture>
                </div>

                <div className='photo-container'>
                <picture>
                <source className='work-sample' srcset="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.webp" />
                <img className='work-sample' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/fnworksample4.PNG" />
                </picture>
                </div>
                
                </div>
      
                <div className="class-detail-title in-course">
                Upcoming Class Dates
                </div>

  
              
               
            <div className="course-wrap">


  <div className={mOpen? "details" : "hidden"}>
<div className="class-detail-title">
Total Cost
</div>
<div className="class-cost">
$6000 
<Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
</div>

<div className="class-detail-title">
Registration Cost
</div>
<div className="class-cost">
$950 non-refundable deposit
<br/><br/>
Remaining balance of $4950 due one week before course dates
</div>

<div className="class-detail-title class-cost">
Important Details
</div>

<div>

This course is for beginners looking to learn Ombre Brows and Lip Blushing for the first time, as well as for intermediate learners looking to sharpen their machine work skills.  This course is designed for beginners, no experience required, no esthetician license needed <br/><br/>
It will consist of an online AND in-person component.  Your login for the online portion will be sent to you two weeks prior to the course dates, and will require approximately 6-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
<br/><br/> You will learn the basics of machine work, how to use science with beauty to perfect brow and lip shapes on every individual, and how to consult with clients.  You will practice with assignments that are checked by our master artists, and you will become excellent in each required section before advancing.<br/> <br/> Finally, you will learn about how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.  <br/><br/>
Lastly, you will complete work on a live model* prior to your certification.  

<br/><br/>Class times will be from 9am or 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.
<br/><br/> **All necessary supplies and tools needed will be provided. 
<br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous eyebrow permanent makeup done.  Please be sure to choose someone who will be patient, understanding, and comfortable with you.
</div>
</div>





  <div className={sOpen? " " : "hidden"}>
  
  <div className="">
  <div className="">



  <div className="row row-a">
    Introduction to Ombré Brows + Watercolor Lips<br/><span className="italic">5 Hours of At-Home Pre-Course Online Work + 3 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    Option to participate in apprenticeship program: complete 4 live models in our facility within 6 months of course completion.
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Color Theory
    </div>
    <div className="row row-a">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-b">
    Hands on Practice on Latex Skin
    </div>
    <div className="row row-a">
    How to Create Beautiful, Powdery Brows for all Skin Types
    </div>
    <div className="row row-b">
    You will work on a Live Model
    </div>
    <div className="row row-a">
    Certificate of Completion
    </div>
    </div>

    </div></div>     </div> 
        
            
            
            
      
                


  
    <div className="div-spacer"></div>
</div>
  )


    ;
    }