import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'


export default function AvailableCourses(props) {

  const [open, setOpen] = useState(null)
  const [description, setDescription] = useState(null)
  const [register, setRegister] = useState(false)
  const [mOpen, setmOpen] = useState(false)
  const [sOpen, setsOpen] = useState(false)
  const [courseDetail, setCourseDetail] = useState(null)
  const [courseType, setCourseType] = useState(null)

  const { fetchAvailableCourses,
    courses, type, key
   } = props


useEffect(() => {
fetchAvailableCourses() 

}, [])

useEffect(() => {
  if (props.match.params.name && !courseType || courseType !== props.match.params.name)
    setCourseType(props.match.params.name)
    window.scrollTo(0, 0)
  }, [props.match.params.name])

function setM() {
  mOpen ? setmOpen(false) : setmOpen(true) 
  
}

function setS() {
  sOpen ? setsOpen(false) : setsOpen(true) 
  
}

function openCourse(num) {
  setCourseDetail(num)
}


if (courseType && courseType === "ombre-lips") {
  return (
    <div className="courses-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">
    <video loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/ombrelips.mp4`} poster={`https://cosworksamples.s3.us-east-2.amazonaws.com/IMG_2010.jpg`}/>

                <div className="mobile-banner-overlay">
                </div>
              
                <img className="video-name-image a" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/ombretitle2.png`}></img>
                <img className="video-name-image b" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/lipstitle2.png`}></img>
                <div className="remaining-title">
                  4 Day Fundamental Training <br/>No Experience Required
                </div>
                </div></div>

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "New York City") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            {courses[key].bookNow}
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <div className="div-spacer"></div>
            <div className="course-wrap">

  <div className={"closed-container"} onClick={() => setM()}>
    {mOpen ? "Less Details" : "Cost & Registration Information"}
    <span className="plus">{mOpen ? "-" : "+"}</span>
  </div>

  <div className={mOpen? "details" : "hidden"}>
<div className="class-detail-title">
Total Cost
</div>
<div className="class-cost">
$4700 
<Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
</div>

<div className="class-detail-title">
Registration Cost
</div>
<div className="class-cost">
$950 non-refundable deposit
<br/><br/>
Remaining balance of $3750 due one week before course dates
</div>

<div className="class-detail-title class-cost">
Important Details
</div>

<div>

This course is for beginners looking to learn Ombre Brows and Lip Blushing for the first time, as well as for intermediate learners looking to sharpen their machine work skills.  This course is designed for beginners, no experience required, no esthetician license needed <br/><br/>
It will consist of an online AND in-person component.  Your login for the online portion will be sent to you two weeks prior to the course dates, and will require approximately 6-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
<br/><br/> You will learn the basics of machine work, how to use science with beauty to perfect brow and lip shapes on every individual, and how to consult with clients.  You will practice with assignments that are checked by our master artists, and you will become excellent in each required section before advancing.<br/> <br/> Finally, you will learn about how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.  <br/><br/>
Lastly, you will complete work on a live model* prior to your certification.  

<br/><br/>Class times will be from 9am or 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.
<br/><br/> **All necessary supplies and tools needed will be provided. 
<br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous eyebrow permanent makeup done.  Please be sure to choose someone who will be patient, understanding, and comfortable with you.
</div>
</div>


  <div className={"closed-container"} onClick={() => setS()}>
    {sOpen ? "Less Details" : "Class Curriculum"}
    <span className="plus">{sOpen? "-" : "+"}</span>
 <div>
 </div>
 </div>

  <div className={sOpen? " " : "hidden"}>
  
  <div className="">
  <div className="">



  <div className="row row-a">
    Introduction to Ombré Brows + Watercolor Lips<br/><span className="italic">5 Hours of At-Home Pre-Course Online Work + 3 Days In-Person, Hands-on instruction</span>
    </div>

    <div className="row row-b">
    Option to participate in apprenticeship program: complete 4 live models in our facility within 6 months of course completion.
    </div>
    <div className="row row-a">
    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes PMU Machine and Needles)
    </div>
    <div className="row row-b">
    Brow Mapping, Shaping, & Drawing Techniqes
    </div>
    <div className="row row-a">
    Overview of tools and supplies
    </div>
    <div className="row row-b">
    Color Theory
    </div>
    <div className="row row-a">
    Proper Sanitation & Bloodborne Pathogen Training
    </div>
    <div className="row row-b">
    Hands on Practice on Latex Skin
    </div>
    <div className="row row-a">
    How to Create Beautiful, Powdery Brows for all Skin Types
    </div>
    <div className="row row-b">
    You will work on a Live Model
    </div>
    <div className="row row-a">
    Certificate of Completion
    </div>
    </div>

    </div></div>     </div> 
        
            
            
            
      
                


  
    <div className="div-spacer"></div>
</div>
  )
}


if (courseType && courseType === "ombre") {
  return (
    <div className="courses-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">
    <video loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/RPReplay_Final1656548736.mp4`} poster={`https://cosworksamples.s3.us-east-2.amazonaws.com/IMG_2011.jpg`}/>

                <div className="mobile-banner-overlay">
                </div>
              
                <img className="video-name-image c" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/ombretitle2.png`}></img>
                                        <div className="remaining-title a">
                                          2 Day Fundamental Training <br/>No Experience Required
                                        </div>
                </div></div>

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "New York City") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            {courses[key].bookNow}
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <div className="div-spacer"></div>
            <div className="course-wrap">

  <div className={"closed-container"} onClick={() => setM()}>
    {mOpen ? "Less Details" : "Cost & Registration Information"}
    <span className="plus">{mOpen ? "-" : "+"}</span>
  </div>

  <div className={mOpen? "details" : "hidden"}>
  <div className="class-detail-title">
  Total Cost
                </div>
                <div className="class-cost">
                  $3200 
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $600 non-refundable deposit 
                  <br/><br/>
                  Remaining balance of $2600 due one week before course dates
                </div>

                <div className="class-detail-title class-cost">
                  Important Details
                </div>
     
                <div>
                  
                This course is for beginners looking to learn Ombre Brows for the first time, as well as for intermediate learners looking to sharpen their Ombre Brow skills.  <br/><br/>
                It will consist of an online AND in-person component.  Your login for the online portion will be sent to you two weeks prior to the course dates, and will require approximately 6-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
                <br/><br/> You will learn the basics of machine work, how to use science with beauty to perfect brow shapes on every individual, and how to consult with clients.  You will practice with assignments that are checked by our master artists, and you will become excellent in each required section before advancing.<br/> <br/> Finally, you will learn about how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.  <br/><br/>
                Lastly, you will complete work on a live model* prior to your certification.  
                
                <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.
                <br/><br/> **All necessary supplies and tools needed will be provided.  Your supplies will also be enough to get started on your first few clients** 
                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous eyebrow permanent makeup done.  Please be sure to choose someone who will be patient, understanding, and comfortable with you.
                </div>
                </div>


  <div className={"closed-container"} onClick={() => setS()}>
    {sOpen ? "Less Details" : "Class Curriculum"}
    <span className="plus">{sOpen? "-" : "+"}</span>
 <div>
 </div>
 </div>

  <div className={sOpen? " " : "hidden"}>
  
  <div className="">
  <div className="">



  <div className="row row-a">
                                      Introduction to Ombré Brows<br/><span className="italic">10 Hours of At-Home Pre-Course Online Work + 2 Days In-Person, Hands-on instruction</span>
                                      </div>
                            
                                      <div className="row row-b">
                                      Option to participate in apprenticeship program: complete 4 live models in our facility within 6 months of course completion.
                                      </div>
                                      <div className="row row-a">
                                      Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients (includes PMU Machine and Needles)
                                      </div>
                                      <div className="row row-b">
                                      Brow Mapping, Shaping, & Drawing Techniqes
                                      </div>
                                      <div className="row row-a">
                                      Overview of tools and supplies
                                      </div>
                                      <div className="row row-b">
                                      Color Theory
                                      </div>
                                      <div className="row row-a">
                                      Proper Sanitation & Bloodborne Pathogen Training
                                      </div>
                                      <div className="row row-b">
                                      Hands on Practice on Latex Skin
                                      </div>
                                      <div className="row row-a">
                                      How to Create Beautiful, Powdery Brows for all Skin Types
                                      </div>
                                      <div className="row row-b">
                                      You will work on a Live Model
                                      </div>
                                      <div className="row row-a">
                                      Certificate of Completion
                                      </div>
                                      </div>

    </div></div>     </div> 
        
            
            
            
      
                


    <div className="div-spacer"></div>
  
</div>
  )
}



if (courseType && courseType === "lips") {
  return (
    <div className="courses-page desktop-view">
    <div className="course-wrap-inside">

    <div className="mobile-banner">
                                    <video loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/lipsvid.mp4`} poster={`https://cosworksamples.s3.us-east-2.amazonaws.com/IMG_2010.jpg`}/>
                                                <div className="mobile-banner-overlay">
                                                </div>
                                              
                                                <img className="video-name-image c" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/lipstitle2.png`}></img>
                                                <div className="remaining-title a">
                                                  2 Day Fundamental Training <br/>No Experience Required
                                                </div>
                </div></div>

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "New York City") {
            

            return (
              <div className="course-wrap">
                
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            Enroll Now
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <div className="div-spacer"></div>
            <div className="course-wrap">

  <div className={"closed-container"} onClick={() => setM()}>
    {mOpen ? "Less Details" : "Cost & Registration Information"}
    <span className="plus">{mOpen ? "-" : "+"}</span>
  </div>

  <div className={mOpen? "details" : "hidden"}>
  <div className="class-detail-title">
  Total Cost
                </div>
                <div className="class-cost">
                  $3800
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $600 non-refundable deposit 
                  <br/><br/>
                  Remaining balance due one week before course dates
                </div>

                <div className="class-detail-title class-cost">
                  Important Details
                </div>
     
                <div>
                  
                This course is designed for beginners, no experience required, no esthetician license needed.<br/><br/> You will complete work on a live model provided by YOU prior to your certification.  
                <br/><br/>
                Class times will be from 9/10am - 7pm (ending times may vary so please allow for longer times if necessary).  
                <br/><br/>
                **All necessary supplies will be provided.  However, this course does not include a PMU Machine, but if you do not have one, please contact us to purchase for the course.  Your supplies will also be enough to get started on your first few clients**
                


                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous eyebrow permanent makeup done.  Please be sure to choose someone who will be patient, understanding, and comfortable with you.
                </div>
                </div>


  <div className={"closed-container"} onClick={() => setS()}>
    {sOpen ? "Less Details" : "Class Curriculum"}
    <span className="plus">{sOpen? "-" : "+"}</span>
 <div>
 </div>
 </div>

  <div className={sOpen? " " : "hidden"}>
  
  <div className="">
  <div className="">



  <div className="row row-a">
                                          Introduction to Lip Blush<br/><span className="italic">One Day In-Person, Fully Immersive, Hands-On Class</span><br/>*Must have prior machine work experience: Certification in Ombré Brows or Lip Blush from ours or another institution**
                                          </div>
                                          <div className="row row-b">
                                          Kit with Pigments & Needle Cartridges (Machine Not Included)
                                          </div>
                                          <div className="row row-a">
                                          Lip Mapping & Shaping Techniques
                                          </div>
                                          <div className="row row-b">
                                          Overview of tools and supplies
                                          </div>
                                          <div className="row row-a">
                                          Color Theory For Lip Colors
                                          </div>
                                          <div className="row row-b">
                                          How to Create Beautiful, Blushed Lips with Precise Cupid's Bows
                                          </div>
                                          <div className="row row-a">
                                          You will work on a Live Model
                                          </div>
                                          <div className="row row-b">
                                          Certificate of Completion
                                          </div>
                                          </div>

    </div></div>     </div> 
        
            
            
            
      
                


  
    <div className="div-spacer"></div>
</div>
  )
}


if (courseType && courseType === "microblading") {
  return (
    <div className="courses-page desktop-view">
    <div className="course-wrap-inside">
    <div className="mobile-banner">
    <video loop muted defaultMuted autoPlay controls = '' playsInline className="mobile-vid" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/MTVideo+4.MP4`} poster={`https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_1055.jpg`}/>
                        <div className="mobile-banner-overlay">
                        </div>
                      
                        <img className="video-name-image" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/microbladingTitle.png`}></img>
                        <div className="remaining-title slogan2">
                        2 Day Fundamental Training <br/>No Experience Required
                        </div>
                </div></div>

                {courses ? Object.keys(courses).map((key) => {
          
            
          if (courses[key].courseType === courseType && courses[key].location == "New York City") {
            

            return (
              <div className="course-wrap">
            {!courses[key].sold ? courses[key].upcoming ? 
            <a className="waitlist" href={`mailto:cosmetinkbeauty@gmail.com?subject=${courses[key].courseType} class waitlist&body=Please let us know your name and cell number, and we'll update you when we finalize details on the next course.`}>
              Email Us To Join Waitlist
            </a> :
            <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now">
            Enroll Now
            </a>
            :             <a target="_blank" href={`https://app.acuityscheduling.com/schedule.php?owner=17444389&appointmentType=${courses[key].appointmentType}`} className="enroll-now-sold">
            <div className="enroll-text">Sold Out</div>
            </a>}
            
            <div className="gray-wrap">
                <div className="upcoming">
                Upcoming Class - {courses[key].location}
                </div>
                  <div className="month">
                  {courses[key].dates.split(" ")[0]}
                  </div>
                  <div className="year">
                  {
                  courses[key].dates.split(" ").slice(1,10).join(" ")
                  }
                  </div>
                </div></div>)}}) : null }
                <div className="div-spacer"></div>
            <div className="course-wrap">

  <div className={"closed-container"} onClick={() => setM()}>
    {mOpen ? "Less Details" : "Cost & Registration Information"}
    <span className="plus">{mOpen ? "-" : "+"}</span>
  </div>

  <div className={mOpen? "details" : "hidden"}>
  <div className="class-detail-title">
  Total Cost
                </div>
                <div className="class-cost">
                  $3200
                  <Link className="regular" to="/financing"><br/>Want to explore our financing options?</Link><br/>
                </div>
                <div className="class-detail-title">
                  Registration Cost
                </div>
                <div className="class-cost">
                  $600 non-refundable deposit
                  <br/><br/>
                  Remaining balance of $2600 due one week before course dates
                </div>

                <div className="class-detail-title class-cost">
                  Important Details
                </div>
     
                <div>
                  
                This course is for beginners looking to learn microblading for the first time, as well as for intermediate learners looking to sharpen their Microblading skills.                This course is designed for beginners, no experience required, no esthetician license needed. <br/><br/>
                It will consist of an online AND in-person component.  Your login for the online portion will be sent to you two weeks prior to the course dates, and will require approximately 6-10 hours of at-home study prior to the course.  You will have several assignments to complete and submit, so be sure you will be able to block out some time to do this work.
                <br/><br/> You will learn the basics of microblading, how to use science with beauty to perfect brow shapes on every individual, and how to consult with clients.  You will practice with assignments that are checked by our master artists, and you will become excellent in each required section before advancing.<br/> <br/> Finally, you will learn about how to handle clients at the procedure, how to prepare and protect yourself against liability, and how to start on your business path.  <br/><br/>
                Lastly, you will complete work on a live model* prior to your certification.  
                
                <br/><br/>Class times will be from 10am - 7pm (ending times may vary so please allow for longer times if necessary).  Be prepared to practice at home after your first class day.  This will be important for you to be able to do your absolute best work on your live model the following day.
                <br/><br/> **All necessary supplies and tools needed will be provided.  Your supplies will also be enough to get started on your first few clients** 
                <br/><br/>*You are responsible for providing a model for your live model work.  Models must not have had previous eyebrow permanent makeup done.  Please be sure to choose someone who will be patient, understanding, and comfortable with you.
                </div>
                </div>


  <div className={"closed-container"} onClick={() => setS()}>
    {sOpen ? "Less Details" : "Class Curriculum"}
    <span className="plus">{sOpen? "-" : "+"}</span>
 <div>
 </div>
 </div>

  <div className={sOpen? " " : "hidden"}>
  
  <div className="">
  <div className="">



  <div className="row row-a ">
                    Introduction to Microblading<br/><span className="italic">10 hours of At-Home Pre-Course Online Work + 2 Days In-Person, Hands-On Instruction</span>
                    </div>
                    
                    <div className="row row-b">
                    Full Introdutory Kit with all Tools & Supplies needed to practice and begin working on your first clients
                    </div>
                    <div className="row row-a">
                    Brow Mapping, Shaping, & Drawing Techniqes
                    </div>
                    <div className="row row-b">
                    Overview of tools and supplies
                    </div>
                    <div className="row row-a">
                    Color Theory
                    </div>
                    <div className="row row-b">
                    Proper Sanitation & Bloodborne Pathogen Training
                    </div>
                    <div className="row row-a">
                    Hands on Practice on Latex Skin
                    </div>
                    <div className="row row-b">
                    How to Create Beautiful, Crisp Strokes for all Skin Types
                    </div>
                    <div className="row row-a">
                    You will work on a Live Model
                    </div>
                    <div className="row row-b">
                    Certificate of Completion
                    </div>
                                          </div>

    </div></div>     </div> 
        
            
            
            
      
                


    <div className="div-spacer"></div>
  
</div>
  )
}








    return (
      <></>
    )

    ;
    }