import axios from 'axios';

export const fetchAvailableCourses = () => {
    return axios.get(`/api/courses/`) }

export const fetchUserCourses = (user_id) => {
    return axios.get(`/api/users/${user_id}/usercourses/`)
}

export const fetchCourseMaterial = (course_id) => {
    return axios.get(`/api/sections/${course_id}`)
}

export const fetchChapters = (course_id) => {
    return axios.get(`/api/chapters/${course_id}`)
}

export const signupCourse = (user_id, courseData) => {
    return axios.post(`/api/users/${user_id}/usercourses/`, courseData)
}

export const fetchAssignments = (course_id) => {
    return axios.get(`api/assignments/${course_id}`)
}

