import { connect } from 'react-redux';
import AvailableCourses from './courses';
import { logout } from '../../actions/session_actions';
import {fetchAvailableCourses, fetchUserCourses} from '../../actions/course_actions'

const mapStateToProps = (state) => {
  return {
    courses: state.courses.availableCourses
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAvailableCourses: () => dispatch(fetchAvailableCourses()),
    fetchUserCourses: (user_id) => dispatch(fetchUserCourses(user_id)),
    logout: user => dispatch(logout(user)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableCourses);