import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'


export default function Trainers(props) {
    const [open, setOpen] = useState(null)
    const [cost, setCost] = useState(0)
    const [weekly, setWeekly] = useState(1000)
    const [yearly, setYearly] = useState(53000)
    const [pos, setPos] = useState(0)
    const [courseDetail, setCourseDetail] = useState(null)
    const [beginner, setBeginner] = useState(true)


    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("scroll", handleScroll);
      }, [])

      useEffect(() => {
        window.scrollTo(0, 0)
        document.querySelector('video').playbackRate = .4;
         }, [])
    
    function openCourse(num) {
      setCourseDetail(num)
    }

    function setProgram() {
      beginner ? setBeginner(false) : setBeginner(true)
    }
    
      function handleScroll() {
          
        const currentScrollPos = (window.pageYOffset - 200);
        const visible = currentScrollPos > 0;
      
        if (currentScrollPos > 0) {
        setPos(currentScrollPos + 200)
        }
      };
      

  

    return (
        <div className="individual-course-page c desktop-view">
          <div className='square-container'>
          <video playsInline muted autoPlay loop className='width-100 fade-in fit-container' src="https://cosacademyvideos.s3.us-east-2.amazonaws.com/IMG_0936.mp4"></video>
          </div>

                 <div className="course-program-title abs-title">
        NIKKY
      </div>
   <img className="artist" src={`https://cosworksamples.s3.us-east-2.amazonaws.com/NikkyNew23.PNG`}></img>
   <div className="artist-box">
   NIKKY ALTEMOSE, FOUNDER OF COSMETINK, IS A RENOWNED COSMETIC TATTOO ARTIST WHO HAS PERFORMED OVER 7,000 SUCCESSFUL PROCEDURES. NIKKY STARTED IN THE BEAUTY INDUSTRY IN 2010 AS A PROFESSIONAL MAKEUP ARTIST. IN 2016 SHE STUDIED AND BECAME AN EXPERT AT SKIN ANATOMY WHILE OBTAINING HER AESTHETICIAN LICENSE.

SHE IS A MASTER ARTIST AND MASTER TRAINER PRACTICING 6+ YEARS IN THE INDUSTRY & A CERTIFIED INSTRUCTOR. SHE HAS TRAINED AND MENTORED MANY ARTISTS IN THE LEHIGH VALLEY AND SURROUNDING AREAS, WHO HAVE GONE OFF TO BE ON THEIR OWN AND EVEN OPEN THEIR OWN BUSINESS.

NIKKY HERSELF HAS BEEN TRAINED BY WORLD RENOWNED INTERNATIONAL TRAINERS, ALLOWING HER TO ALWAYS HAVE THE NEWEST, BEST, AND MOST EFFECTIVE TECHNIQUES — FEATURING BUT NOT LIMITED TO EUROPEAN, RUSSIAN, AND KOREAN PERMANENT MAKEUP TECHNIQUES.

NIKKY SPECIALIZES IN CREATING HYPER-REALISTIC LOOKING COSMETIC TATTOOING. SHE IS HIGHLY SKILLED IN COLOR THEORY & THE ANATOMY OF THE FACE TO ENHANCE YOUR FACIAL FEATURES, AND CREATE SYMMETRY WHETHER YOUR STYLE IS NATURAL OR BOLD.
   </div>

  

   <div className="course-program-title abs-title-r">
     SEAN
      </div>
   <img className="artist s" src={`https://cosworksamples.s3.us-east-2.amazonaws.com/SeanNew23a.PNG`}></img>
             <div className="artist-box">
             SEAN HAS A UNIQUE BACKGROUND; HAVING EARNED A DEGREE IN CHEMISTRY FROM THE UNIVERSITY OF MIAMI, HE HAS ALSO WORKED AS A FASHION MODEL FOR OVER 12 YEARS IN NEW YORK, MIAMI, LA, TOKYO, MILAN, AND MORE. HIS CLIENTS INCLUDE CALVIN KLEIN, TOMMY HILFIGER, LEVI'S, NORDSTROM, SAKS FIFTH AVENUE, KOHL'S, BON TON, TARGET, UNIQLO, BANANA REPUBLIC, JOHN VARVATOS, MACY'S, JC PENNEY, ABERCROMBIE.

SEAN GAINED HIS PERSPECTIVE ON BEAUTY FROM HIS EXTENSIVE WORK WITH MODELS AND MAKEUP ARTISTS FROM AROUND THE WORLD. THIS, ALONG WITH HIS SCIENCE BACKGROUND, ALLOWS HIM TO COMBINE HIS KNOWLEDGE OF SKIN ANATOMY, COLOR THEORY, AND BEAUTY TO CREATE BEAUTIFUL BROWS AND LIPS.

SEAN'S MISSION IS TO EMPHASIZE THE NATURAL BEAUTY OF EACH OF HIS CLIENTS. HIS WORK IS MEANT TO BRING OUT THE UNIQUE, YET AWESOME QUALITIES IN EACH. TRAINED BY THE RENOWNED ARTIST AND CO-OWNER OF COSMETINK NIKKY ALTEMOSE, SEAN'S TECHNIQUE IS SIMILAR IN THAT IT IS DEFINED BY ELEGANT AND PRECISE STROKES.
   </div>
      </div>
      
      )}