import { RECEIVE_CLIENT_SECRET } from '../actions/checkout_actions';
  
  const CheckoutReducer = (state = { secret: undefined}, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_CLIENT_SECRET:
        newState.secret = action.secret.data;
        return newState;
      default:
        return state;
    }
  };
  
  export default CheckoutReducer;