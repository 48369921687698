import React, {Suspense, lazy} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBarContainer from './nav/navbar_container';
import './App.css';
import './Course.css'
import './nav/navbar.css'

import AvailableCourses from './main/courses_container';
import NYAvailableCourses from './main/ny_courses_container';
import FAQs from './main/faqs';

import Financing from './main/financing';
import Apprenticeship from './main/apprenticeship';
import Whyus from './main/whyus';
import Beginner from './main/beginner';
import LandingPage from './main/landing'
import Bottom from './main/bottom';
import Gallery from './main/gallery';
import BottomNav from './nav/bottom_nav'
import AllCourses from './main/all_courses_container';
import Location from './main/location';
import Trainings from './main/trainings';
import NewYork from './main/new_york_destination_trainings';
import Trainers from './main/trainers';
import History from './main/history';
import AAMCourse from './main/aam_course';


const FundamentalClasses = lazy(() => 
import (`./main/fundamental_classes`))

const MasterClasses = lazy(() => 
import (`./main/master_classes`))


const App = () => (
  <div className="main">
      <Suspense fallback={<div>
                      </div>}>
             <NavBarContainer/>
    <Routes>
    <Route exact path="/" element={<LandingPage/>} />
    <Route exact path="/fundamental-classes" element={<FundamentalClasses/>} />
    <Route exact path="/master-classes" element={<MasterClasses/>} />
    <Route exact path="/aam-fundamentals-course" element={<AAMCourse/>} />
      <Route  exact path="/all-courses" element={<AllCourses/>} />
      <Route  exact path="/history" element={<History/>} />
      <Route  exact path="/location" element={<Location/>} />
      <Route  exact path="/trainings" element={<Trainings/>} />
      <Route  exact path="/newyork-trainings" element={<NewYork/>} />
      <Route  exact path="/trainers" element={<Trainers/>} />
      <Route  exact path="/gallery" element={<Gallery/>} />
      <Route  exact path="/beginner" element={<Beginner/>} />
      <Route  path="/courses/:name" element={<AvailableCourses/>} />
      <Route  path="/ny-courses/:name" element={<NYAvailableCourses/>} />

      <Route  exact path="/faqs" element={<FAQs/>} />


      <Route  exact path="/financing" element={<Financing/>} />
      <Route  exact path="/apprenticeship" element={<Apprenticeship/>}/>
      <Route  exact path="/whyus" element={<Whyus/>}/>
      </Routes>
    <Bottom/>
    <BottomNav/>
    </Suspense>
  </div>
);

export default App;