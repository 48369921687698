import * as Util from '../util/checkout'

export const RECEIVE_CLIENT_SECRET = "RECEIVE_CLIENT_SECRET"

export const receiveClientSecret = secret => ({
    type: RECEIVE_CLIENT_SECRET,
    secret
})

export const fetchPaymentIntent = (info) => dispatch => {
    Util.fetchPaymentIntent(info).then(secret => {
        dispatch(receiveClientSecret(secret))
    })
}
