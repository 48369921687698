import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";


export default function Location(props) {



    const [open, setOpen] = useState(null)

  
    function setOpenSection(num) {
      if (open === num) {
        setOpen(null)
      } else {
        setOpen(num)
      }
    }
  
    useEffect(() => {
      window.scrollTo(0, 0)
       
       }, [])




 
  

    return (
      <>



      <div className='left-half banner-top-container fade-in'>
        <img className='banner-image move-up' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/locationbanner.jpg">
          
        </img>
        <div className='banner-image-overlay'></div>
      </div>


        


         <div className="right-half desktop-override">
         <div className="desktop-page2 c desktop-override">
<div className="padding-bottom-20 desktop-override">
                  
                  
                  <div className="">

                            <div className="bnbt wht desktop-override">
        COSMETINK® <br/>PERMANENT COSMETICS STUDIO & ACADEMY
        </div>
        <div className="bnba wht  wht">
          3859 Nazareth Pike, Unit 103<br/>
          Bethlehem, PA 18020<br/>
          <a className="wht-email" href="mailto:cosmetinkbeauty@gmail.com">cosmetinkbeauty@gmail.com</a>
        </div>
<br/>

              </div></div>         
  </div>
    


  <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d773617.6840724369!2d-75.23010343128904!3d40.76325467428503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c443557b11e64d%3A0x601c04db1170f5b6!2sCOSMETINK%C2%AE%20Permanent%20Cosmetics%20Studio%20%26%20Academy!5e0!3m2!1sen!2sus!4v1657835141207!5m2!1sen!2sus" width="600" height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
</div>



         </>
    );
    }