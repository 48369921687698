import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";
import {Helmet} from 'react-helmet'

export default function Apprenticeship(props) {



    const [open, setOpen] = useState(null)

  
    const [pos, setPos] = useState(false)

  
    function setOpenSection(num) {
      if (open === num) {
        setOpen(null)
      } else {
        setOpen(num)
      }
    }
    window.addEventListener("scroll", handleScrollClass);

    function handleScrollClass() {
            
      const currentScrollPos = (window.pageYOffset);
    
      const visible = currentScrollPos > 4;
  
      setPos(visible)
      };
  

  
    useEffect(() => {
      window.scrollTo(0, 0)
       
       }, [])




 
  

    return (
      <div className=''>

<div className='banner-container'>
      <div className='banner-image-overlay vid'></div>
      <div className={!pos ? 'banner-vid' : 'banner-vid-opaque'}>
        <video className='width-100 ' playsInline loop muted autoPlay src="https://cosacademyvideos.s3.us-east-2.amazonaws.com/apprenticeshipvid.mp4"></video>
        </div>
        </div>

<h1 className="page-title mobile-only">Apprenticeship Program</h1>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Permanent Makeup Apprenticeship | Bethlehem, Easton, Allentown, Philly, New York | Pennsylvania</title> 
                <link rel="canonical" href="https://academy.cosmetink.com/" />
                <meta name="description" content="Beginner Certification Classes - Learn Microblading, Ombre Brows, Lip Blush - NO EXPERIENCE NEEDED - NO ESTHETICIAN LICENSE REQUIRED" />
                <meta name="keywords" content="ombre brows training, microblading apprenticeship, ombre brows apprenticeship, lip blush apprenticeship, permanent makeup training, permanent makeup school, permanent makeup classes beginners, microblading certification, ombre brows certification, lip blush training, lip blush certification, best microblading training, aamp training, beauty school pa, learn microblading, tattoo makeup, cosmetic ink, permanent makeup, microblading, best microblading, semi-permanent makeup, microbladed eyebrows, microblading de cejas" />

          </Helmet>     


          <div className="black-friday-special c smaller-bf abs-right-half">
                     APPRENTICESHIP PROGRAM
                 </div>

          <div className='abs-right-half sub'>
                 If you choose to do the apprenticeship, you will be able to take additional models after certification, allowing you the opportunity to build your portfolio and skill set under the guidance of master artist mentors.<br/>
                            <br/>This will also give you insight into what it's like to work inside of a permanent makeup studio.</div>
        


          <div className="sxn apprenticeship-page right-half left-10 height-90">
<br/><br/><br/>
       

                            <div className="smaller j entry blk mobile-only"><br/>All fundamental course programs taken in our Pennsylvania location come with the option to do an apprenticeship in our space.  After you get your certification, all you have to do is opt "in" to the apprenticeship program.<br/><br/>Students wishing to perform services in New Jersey will need to complete the 100-hr AAM Fundamentals Training and are required to complete 12 additional models in their apprenticeship to become AAM Board Certified.


                            <br/><br/>For students in all other states: if you choose to do the apprenticeship, you will be able to take additional models after certification, allowing you the opportunity to build your portfolio and skill set under the guidance of master artist mentors.<br/>
                            <br/>This will also give you insight into what it's like to work inside of a permanent makeup studio.</div>
                            <br/><br/><br/>
                </div>

  


        



         </div>
    );
    }