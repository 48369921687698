import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'


export default function Trainings(props) {
    const [open, setOpen] = useState(null)
    const [cost, setCost] = useState(0)
    const [weekly, setWeekly] = useState(1000)
    const [yearly, setYearly] = useState(53000)
    const [pos, setPos] = useState(0)
    const [courseDetail, setCourseDetail] = useState(null)
    const [beginner, setBeginner] = useState(true)


    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("scroll", handleScroll);
      }, [])
    
    function openCourse(num) {
      setCourseDetail(num)
    }

    function setProgram() {
      beginner ? setBeginner(false) : setBeginner(true)
    }
    
      function handleScroll() {
          
        const currentScrollPos = (window.pageYOffset - 200);
        const visible = currentScrollPos > 0;
      
        if (currentScrollPos > 0) {
        setPos(currentScrollPos + 200)
        }
      };
      

  

    return (
        <div className="courses-page">
   

          <div className=" sxn">

         
          <div className="paragraph-title c main-title">
       
            BEGINNER<br/>CERTIFICATION PROGRAMS <br/>
            <div className="font-30">New York City</div>
  </div> 

      <div className="class-sample-row">
      
      <div className={beginner ? "class-sample" : "hidden"}>
      <Link to='/ny-courses/ombre'>
     <img src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/OmbreBrowsClass2.png`}>
     </img>
     <div className="course-program-title">
        Ombre Brows
      </div>
      <div className="course-program-subtitle">
        2 Day Fundamental Training<br/>No Experience Needed
 
      <Link to="/ny-courses/ombre">
          <div className="click-thru">LEARN MORE <span className="arrow">⟶</span></div>
          </Link>
     </div>
     </Link>
     </div>
     <div className={beginner ? "class-sample" : "hidden"}>

     <Link to='/ny-courses/microblading'>
     <img src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/Microblading2.png`}>
     </img>
     <div className="course-program-title">
       MICROBLADING
      </div>
      <div className="course-program-subtitle">
        2 Day Fundamental Training<br/>No Experience Needed
 
      <Link to="/ny-courses/microblading">
          <div className="click-thru">LEARN MORE <span className="arrow">⟶</span></div>
          </Link>
     </div>
     </Link>
     </div>

     
     <div className={beginner ? "class-sample" : "hidden"}>
       <Link to="/ny-courses/ombre-lips">
     <img src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/OandLClass2.jpg`}>
     </img>
     <div className="course-program-title">
        Ombre Brows + <br/>Lip Blush
      </div>
      <div className="course-program-subtitle">
        3 Day Fundamental Training<br/>No Experience Needed
 
      <Link to="/ny-courses/ombre-lips">
          <div className="click-thru">LEARN MORE <span className="arrow">⟶</span></div>
          </Link>
     </div>
     </Link>
     </div>

     </div>
     </div>



     
          
      </div>
      
      )}