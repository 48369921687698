
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AvailableCourses from './courses_container';
import Moveable from "react-moveable";


export default function Whyus(props) {



    const [open, setOpen] = useState(null)

  
    function setOpenSection(num) {
      if (open === num) {
        setOpen(null)
      } else {
        setOpen(num)
      }
    }
  




 
  

    return (
      <>






        


         <div className="">
         <div className="apprentice-page">
        <div className="">

        <div className="section relative">
        </div>
        <div className="inline-block">


<video loop muted defaultMuted autoPlay controls = '' playsInline className="whyus-vid" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/cos%2Bclasses+(1).mp4`} poster={`https://cosacademyassets.s3.us-east-2.amazonaws.com/acadvidposter.png`}/>
</div>


                        
 
                           <div className="width-96">
                           <div className="uppercase f-20 black-friday-special-2 uppercase small-c c smaller-bf">Why COSmetink?</div>
                     
<div className="uppercase smaller j entry"><br/>
As the leading Permanent Cosmetic Studio & Academy in the Lehigh Valley, Cosmetink has been in business for over 6 years, and its master artists have completed over 8000 client beauty transformations.
<br/><br/>Nikky Altemose, the visionary CEO behind Cosmetink, has been recognized as a Master Trainer by the internationally renowned Beauty Angels, one of the leading Permanent Makeup organizations in the world, and a Master Artist by Mara Pro.  Nikky’s skill set comes from years of training
with world-renowned artists, keeping her up to date with the latest, most advanced techniques, all of which get passed down to her team and her students.<br/><br/>
Nikky and her team are passionate about creating a path for men and women who want to break into the permanent makeup world, and have successfully trained many who have already gone off and started their own businesses or who are currently employed in the field.<br/><br/>
The team at Cosmetink teaches you the key to creating looks that transcend time and trends.  Our expertise in the industry, backed by our high volume of clientele, will help kick start your new or continued career in permanent cosmetics.

</div></div></div>
</div>
          
          <div className="section4">
 

            <div className="course-container">

        
            <div className="footer">
        <div onClick={() => setOpenSection(1)} className="hidden drop">
        FAQs
        <span className="add">+</span>
        </div>
        <div className={open === 1 ? "drop light" : "hidden"}>
          Frequently asked questions
        </div>

        <div onClick={() => setOpenSection(2)} className="drop">
        Contact
        <span className="add">+</span>
        </div>
        <div className={open === 2 ? "drop light" : "hidden"}>
        Shoot us an email at <a href="mailto:cosmetinkbeauty@gmail.com">cosmetinkbeauty@gmail.com</a>
        </div>

        <div onClick={() => setOpenSection(3)}  className="drop">
        About Us
        <span className="add">+</span>
        </div>
        <div className={open === 3 ? "drop light" : "hidden"}>
      Serving as the premier permanent cosmetics studio in the Lehigh Valley since 2017, COSMETINK has served thousands of happy clients from all over the tristate area including but not limited to NYC, Lehigh Valley PA, Delaware, Maryland, Virgina, and more.  
      <br/><br/>
      With a track record for success, CoFounders Nicolette Altemose and Sean Altemose want to bring their knowledge of the craft to you through Cosmetink Academy.  
      <br/><br/>The Academy was first established in late 2019, and starting in 2021, Nicolette and Sean have a reignited motivation and passion to bring it to more students. 
      
      <br/><br/>Check us out on instagram! <a href="https://www.instagram.com/cosmetinkacademy"><i class="fab fa-instagram"></i> @cosmetinkbeauty</a>
        </div>


        <div onClick={() => setOpenSection(4)} className="drop">
        Our Flagship
        <span className="add">+</span>
        </div>
        <div className={open === 4 ? "drop light" : "hidden"}>
        We are located at 3859 Nazareth Pike, Unit 103, Bethlehem, PA 18020.
        Visit our website <a href="https://www.cosmetink.com">cosmetink.com</a> to see our artists, our services, and get to see a little bit more about our day-to-day work.
        </div>

        <br/>
        <br/>
        <div className="c">
        © 2021 COSMETINK, COSMETINK ACADEMY
        <br/> All rights reserved.
        </div>
        </div>
          </div>
         

          
      
         </div>
         </div>



         </>
    );
    }