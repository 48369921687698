import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from "swiper/react";
import EffectPanorama from "./effect-panorama.esm.js";
import {
  A11y,
  Autoplay,
  EffectCreative,
  Keyboard,
  Pagination,
  Navigation, 
  Parallax
} from "swiper/modules";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/effect-creative";
import "swiper/css/keyboard";
import "swiper/css/pagination";

import "./MySwiper.css";





register();

export default function LandingPage(props) {
    const [open, setOpen] = useState(null)
    const [cost, setCost] = useState(0)
    const [weekly, setWeekly] = useState(1000)
    const [yearly, setYearly] = useState(53000)
    const [pos, setPos] = useState(0)
    const [courseDetail, setCourseDetail] = useState(null)
    const [beginner, setBeginner] = useState(true)

  
    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

      window.addEventListener("scroll", handleScroll);

   

    function openCourse(num) {
      setCourseDetail(num)
    }

    function setProgram() {
      beginner ? setBeginner(false) : setBeginner(true)
    }
    
      function handleScroll() {
          
        const currentScrollPos = (window.pageYOffset - 200);
        const visible = currentScrollPos > 0;
      
        if (currentScrollPos > 0) {
        setPos(currentScrollPos + 0)
        }
      };

      const swiperParameters = {
        modules: [EffectCreative, Keyboard, Navigation, Parallax, Pagination, Autoplay],
        observer: true,
        navigation: true, 
        observeParents: true,
        loop: true,
        watchSlidesProgress: true,
        speed: 400,
        initialSlide: 0,
        autoplay: {delay: 2500, enabled: true},
        grabCursor: true,
        effect: "cards",
        slidesPerGroupAuto: false,
        creativeEffect: {
          next: { shadow: true },
          prev: { shadow: true },
          limitProgress: 5,
        },
        pagination: { clickable: true, dynamicBullets: true },
        parallax: {enabled: true},
     
        keyboard: { enabled: true },
        a11y: { enabled: false },
      };


      const swiperParameters3 = {
        modules: [EffectCreative, Keyboard, Navigation, Parallax, Pagination],
        observer: true,
        navigation: true, 
        observeParents: true,
        watchSlidesProgress: true,
        speed: 400,
        initialSlide: 0,
        autoplay: {delay: 3000, enabled: true},
        grabCursor: true,
        effect: "cards",
        slidesPerGroupAuto: false,
        creativeEffect: {
          next: { shadow: true },
          prev: { shadow: true },
          limitProgress: 5,
        },
        pagination: { clickable: true, dynamicBullets: true },
        parallax: {enabled: true},
        keyboard: { enabled: true },
        a11y: { enabled: false },
      };

      const swiperParameters5 = {
        modules: [EffectCreative, Keyboard, Navigation, Parallax, Pagination],
        observer: true,
        navigation: true, 
        observeParents: true,
        watchSlidesProgress: true,
        speed: 400,
        initialSlide: 0,
        autoplay: {delay: 3000, enabled: true},
        grabCursor: true,
        effect: "carousel",
        slidesPerGroupAuto: false,
        creativeEffect: {
          next: { shadow: true },
          prev: { shadow: true },
          limitProgress: 5,
        },
        pagination: { clickable: true, dynamicBullets: true },
        parallax: {enabled: true},
        keyboard: { enabled: true },
        a11y: { enabled: false },
      };

      



    return (
        <div className="desktop-page">
          <Helmet>
                <meta charSet="utf-8" />
                <title>Permanent Makeup Training Academy | Bethlehem, Easton, Allentown, New York City</title> 
                <link rel="canonical" href="https://academy.cosmetink.com/" />
                <meta name="description" content="Beginner Certification Classes - Learn Microblading, Ombre Brows, Lip Blush - NO EXPERIENCE NEEDED - NO ESTHETICIAN LICENSE REQUIRED" />
                <meta name="keywords" content="ombre brows training, permanent makeup training, permanent makeup school, permanent makeup classes beginners, microblading certification, ombre brows certification, lip blush training, lip blush certification, best microblading training, aamp training, beauty school pa, learn microblading, tattoo makeup, cosmetic ink, permanent makeup, microblading, best microblading, semi-permanent makeup, microbladed eyebrows, microblading de cejas" />
          </Helmet>     



          <div className={'front-overlay'}></div> 
          
          {pos > 30 ?  <div className='front-overlay2'></div> : null }
    
      
          <div className=''>
        
         
         <div className={pos < 40 ? 'front-video-container' : 'front-video-container-2'}>
         <video loop muted autoPlay controls = '' playsInline className="width-100" src={window.innerWidth < 800 ? `https://cosacademyassets.s3.us-east-2.amazonaws.com/AcademyLandingMobile.mp4` : `https://cosacademyassets.s3.us-east-2.amazonaws.com/AcademyLanding2.mp4`} poster={window.innerWidth < 800 ? `https://cosacademyassets.s3.us-east-2.amazonaws.com/acadvidmobilePoster.jpg` : 'https://cosmetinklanding.s3.us-east-2.amazonaws.com/academy-link.jpg'}/>
         </div>
         
         {
          <>
            <img className='aam-logo' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/IMG_4959.PNG" />
          <div className='title-main-a-l poppins-semibold slide-in-left'>
           Accredited Training Courses & Apprenticeships
          </div>
          <div className='title-main-l poppins-semibold slide-in-left'>
          The Future Is In<br/>
          <span className='poppins-semibold-italic'>Your Hands</span>
          </div>
          <div className='machine-drop slide-in-right'>
          <img className='machine-drop-img' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/machineDrop.png"/>
         </div>
            
      
     
      <div className="title-main-l2">
       Located in Lehigh Valley, Pennsylvania
      </div>
      <div className="title-main-l3">
            No Prior Experience Required <br/>No Esthetician/Cosmetology License Needed
      </div>
      </>  }
</div> 
     


<div className='width-100 best-artists black-background'> 
<div className={pos > 30 && pos < 590  ? 'title-main-l-moving poppins-light c' : pos > 590 ? 'title-main-l-moving-2 poppins-light' : "hidden"}>
        
         {pos > 40 && pos < 590 ? 
         <>
          We Create&nbsp;
          <span className='poppins-regular-italic'>Top</span>&nbsp;<br/>Permanent Makeup Artists
          </> : pos > 590 
          ? 
          <div className='lowercase'>
          Helping students<br/>achieve <span className='poppins-regular-italic'>their dreams</span><br/>
          </div>: null
          }
          </div> 

          {pos > 590 ?
          <div className='title-main-l-moving-3 poppins-light'>
          <span className='line white-back'></span><span className='poppins-regular-italic'>now it's your turn</span>
          </div> : null}

          <div className={pos > 40 && pos < 590  ? 'jocelyn-container' : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/jocelyn.PNG' />
          </div>

          <div className={pos > 40 && pos < 590 ? 'kayla-container' : "hidden"}>
          <img  src="https://cosacademyassets.s3.us-east-2.amazonaws.com/kayla_model.PNG" />
          </div>

          <div className={pos > 40 && pos < 550  ? 'jacqueline-container' : pos > 550 ? "jacqueline-container-2" : "hidden"}>
          <img src="https://cosacademyassets.s3.us-east-2.amazonaws.com/jacqueline_model.PNG"/>
          </div>

          <div className={pos > 40 && pos < 590  ? 'jen-container' : pos > 590 ? "jen-container-2" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/jeevee.PNG' />
          </div>

     
          <div className={pos > 590 ? "best-artists-row-container" : ""}>
          <div className={pos > 590 ? "best-artists-row" : ""}>


          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student3.PNG' />
          </div>

          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student4.PNG' />
          </div>
          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student5.PNG' />
          </div>
          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student6.PNG' />
          </div>
          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student7.PNG' />
          </div>
 
          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student9.PNG' />
          </div>
          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student10.PNG' />
          </div>
          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student11.PNG' />
          </div>
          <div className={pos > 590 ? "jen-container-3" : "hidden"}>
          <img src='https://cosacademyassets.s3.us-east-2.amazonaws.com/student2.PNG' />
          </div>
          </div>
          </div>

       
</div>



          <div className="width-100">
          <div className="purp-back">
            <div className='grad-purp-back'></div>
            
          <div className="entry-text-low uppercase bold white">
            <div className="space-box">
                <div className={pos < 600 ? "space-title2 studio c white poppins-semibold" : "space-title studio c white poppins-semibold"}>
                  POTENTIAL TO EARN <br/>SIX FIGURES + 
                </div>
                <div className='moving-grad-5'></div>
            <br/>
            <div className="black-friday-special">
            ${500 < (cost + pos - 500).toFixed(0) ? (500).toString() : ((cost + pos-500)* 1).toFixed(0)}
            </div>
            <div className="poppins-regular">
            COST PER PROCEDURE
            </div>
            </div>

            <div className="space-box">
            <div className="black-friday-special">
            ${500 < (cost + pos - 500 ).toFixed(0) ? (2500).toString() : ((cost + pos -500)* 5).toFixed(0)}
            </div>
            <div className="poppins-regular">
            WEEKLY INCOME BASED ON 5 PROCEDURES
            </div>
            </div>

            <div className="space-box">
            <div className="black-friday-special">
            ${500 < (cost + pos - 500 ).toFixed(0) ? (130000).toString() : ((cost + pos-500) * 5 * 53).toFixed(0)}
            </div>
            <div className="poppins-regular">
            YEARLY INCOME
            </div>
            <br/>

          </div>
          </div>
          </div></div>


          <div className='grad-blk-trans'>
         </div>

          <div className="">
     
  
      <div>
        <div>
        
          
 




<video loop muted autoPlay controls = '' playsInline className="width-100 desktop-700px" src={`https://cosacademyassets.s3.us-east-2.amazonaws.com/StateOfArtSpaceNEW-2-2.mp4`} poster={`https://cosacademyassets.s3.us-east-2.amazonaws.com/StateOfArtSpace.jpg`}/>
            <div className='col'>
            <div className="paragraph-title desktop-mid poppins-bold">
                Cutting Edge
           
              </div>
              <a alt="Cosmetink Academy Walkthrough" href="https://cosacademyassets.s3.us-east-2.amazonaws.com/CosmetinkAcademyWalkthruVideo.mp4" target="_blank" className='walkthru-link'>See a Full Walkthrough <i class="fa-duotone fa-up-right-from-square"></i></a>
              <br/><br/>
              We have a top-notch state-of-the-art facility, and we teach the latest in permanent cosmetics techniques, so our students are always leading the way in the industry.<br/>
              <br/>
              We designed our space to be exceptionally creative and inviting because we know that the perfect blend of inspiration, motivation, and a picturesque backdrop is a recipe for success. ✨ Whether you’re capturing the essence of your work or showcasing your portfolio, having an inspiring environment can truly elevate your creativity, and take your work - and your portfolio - to the next level.
      
              </div>
              <br/>

              <div>
              <video loop muted autoPlay controls = '' playsInline className="width-100 desktop-700px" src={`      https://cosacademyassets.s3.us-east-2.amazonaws.com/SampleVidKitCosmetinkAcademy2.mp4`} poster={` https://cosacademyassets.s3.us-east-2.amazonaws.com/SampleKitPoster.jpg`}/>
            
            
         
            <div className='col'>
            <div className="paragraph-title desktop-mid poppins-bold">
                Superior Quality Products
           
              </div>
              <a alt="Cosmetink Academy Walkthrough" href=" https://cosacademyassets.s3.us-east-2.amazonaws.com/FullKitCosmetinkAcademyVideo.mp4" target="_blank" className='walkthru-link'>Check out the Full Video Here <i class="fa-duotone fa-up-right-from-square"></i></a>
              <br/><br/>Available exclusively through COSMETINK®, our machine courses come with our Signature InkBrush™ Machine, which is the newest innovation in machine technology in the world of Permanent Makeup.<br/>
              <br/>All courses come with kits full of top quality products and machines.
              <br/><br/>Our Academy prides itself in ensuring our students are equipt with the latest techniques, products, and knowledge of market trends.  We carefully select clinically trusted brands that feature high-end pigments and mechanics for our students.
      
              </div>
          </div>
          </div>

          
<br/>
<div className=''> 



<div className='manual-slider'>

<div className="manual-slide-container">
  <div className="s">


    <img className='slider-image-5' src="https://cosmetinkshop.s3.us-east-2.amazonaws.com/studentslong_2.JPG" />

  </div>
</div>

<div className="manual-slide-container">
  <div className="s">

    <img className='slider-image-5' src="https://cosmetinkshop.s3.us-east-2.amazonaws.com/studentslong_4.JPG" />

  </div>
</div>


<div className="manual-slide-container">
  <div className="s">


    <img className='slider-image-5' src="https://cosmetinkshop.s3.us-east-2.amazonaws.com/studentslong_3.JPG" />


  </div>
</div>

<div className="manual-slide-container">
  <div className="s">


    <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/studentb4.JPG" />

  </div>
</div>





<div className="manual-slide-container">
  <div className="s">


<img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/sept23_1.JPG" />

  </div>
</div>

<div className="manual-slide-container">
  <div className="s">


   <img className='slider-image-5' src="https://cosmetinkshop.s3.us-east-2.amazonaws.com/studentslong_1.JPG" />

  </div>
</div>

</div>


<div className='col'>
<div className="paragraph-title desktop-mid poppins-bold">
              Apprenticeship Opportunity
           
              </div>
              We are proud to offer an apprenticeship option for our graduates.
              <br/><br/>After completion of your certification program, you will have the option to work on additional models in our facility.<br/>
              <br/>With flexible times and self-directed scheduling, our students can use this to build on their skills and grow their portfolio under the guidance of our master trainers.
    
     </div>
     <br/>



     <div className='manual-slider2'>


     <div className="manual-slide-container2">
  <div className="s">

    <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/ClassGroupA.jpg" />
   
  </div>
</div>



<div className="manual-slide-container2">
  <div className="s">

  <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/group2.jpg" />
  </div>
</div>

<div className="manual-slide-container2">
  <div className="s">

    <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/group1.jpg" />
   
  </div>
</div>

<div className="manual-slide-container2">
  <div className="s">

    <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/ClassGroupB.jpg" />
   
  </div>
</div>

<div className="manual-slide-container2">
  <div className="s">

  <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/group3.jpg" />
  </div>
</div>

<div className="manual-slide-container2">
  <div className="s">

  <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/group4.jpg" />
  </div>
</div>

<div className="manual-slide-container2">
  <div className="s">

  <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/group5.jpg" />
  </div>
</div>

<div className="manual-slide-container2">
  <div className="s">

  <img className='slider-image-5' src="https://cosacademyassets.s3.us-east-2.amazonaws.com/group6.jpg" />
  </div>
</div>
</div>



<div className='col'>

<div className="paragraph-title desktop-mid poppins-bold">
              Lifetime Support
           
              </div>
              With over a decade in the permanent makeup world and 27,000+ successful procedures, our master trainers are here to stay!<br/><br/>Not to mention, you have a network full of successful graduates!  We encourage all of our students to support each other and grow together.<br/><br/>
              As you go off on your journey in the world of permanent cosmetics, we are here for you, and we want you to continue doing great work.  We are committed to you for the long haul.
              </div>
 </div>
          </div>
   
          </div>

  




  <div className="gray-back">
  <div className="c">
          <div className="paragraph-title  poppins-semibold c">
         Reviews<br/>From Our Graduates
          </div>
 
         
          <a className="bnb uppercase " href="https://www.google.com/search?q=cosmetink&oq=cosmetink+&aqs=chrome.0.69i59j46i175i199i512j0i512j69i60l5.1906j0j4&sourceid=chrome&ie=UTF-8#lrd=0x89c443557b11e64d:0x601c04db1170f5b6,1,,," > Click to see all <i class="fa-thin fa-up-right-from-square"></i></a>
          </div>
          <br/>
    <div className="review-row">

    <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Antoinette Hernandez
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          I can't express enough how absolutely incredible my experience at Cosmetink has been! About a year and a half ago, I embarked on this exciting journey with Nikky's microblading course. At that point, I was a complete novice in the industry, working a regular job at Amazon. Little did I know, this decision would change my life, and it's all thanks to the extraordinary trainers, Nikky and Sean.

Nikky and Sean aren't just trainers; they are mentors who go above and beyond to ensure your success. They exude patience, encouragement, and expertise. Their teaching is not only thorough but also filled with humor and warmth, making every moment of the course an absolute joy.

What sets Cosmetink apart is the unwavering support you receive long after the training ends. Nikky and Sean continued to be there for me, reviewing my practice work, answering my every question, and even allowing me to bring models to the studio as I gained confidence.

The pivotal moment came when I decided to enroll in the ombré brow course, and once again, I was blown away by the exceptional experience. If you're contemplating your training destination in the makeup industry, without a doubt, I wholeheartedly recommend Cosmetink.</div>
        </div>

        </div>


        <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Taylor Anthony
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          Nikki and Sean are the sweetest humans alive! So kind, so thorough and so passionate about what they teach! I will always go back to them for any new training I plan to get! Their work is phenomenal and the students work is stunning too due to their thorough and  precise techniques! Cosmetink is absolutely the place to go when learning PMU! </div>

        </div>
        </div>


        <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Jennifer V.
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          Wow where do I begin? Not only were the classes very detailed and of course relevant to the topics and techniques taught, the instructors made the learning process so seamless. They are knowledgeable of their craft but also patient and kind which is what I personally need to learn well and retain information. They are able to keep it real with you all while staying professional the entire time. The topics and techniques learned were great but the environment and demeanor the instructors provided is what made the whole entire process worth it all every step of the way. They made the classes creative and answered questions beyond just makeup. Overall, very well rounded curriculum to get students ready for the real deal.
          </div>

        </div>
        </div>

        <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Amber McQuay
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          This is the 3rd time I’ve been certified  through Cosmetink.  My experience taking the lip blush class was phenomenal. I loved the energy and instructors so much that I took the apprenticeship they offer as well.  Those experiences led me to get the ombre brow certification. The master artist/instructors are extremely talented and their work speaks for itself.  For me it was an honor to get to learn their artistry and techniques.
          
          </div>

        </div>
        </div>

        <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Melody Blossom
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          My school experience at Cosmetink was amazing!!! The instructors and staff were very kind and helpful. Very positive, encouraging, and supportive through any issues I faced through my experience. And most importantly, they’re great teachers! They made sure I understood throughout the class and provided consistent guidance and feedback, both positive and negative. I just received my certification for lip blushing and ombré brows and I can’t wait to go back for my apprenticeship! It’s a great school and studio filled with talented artists and great vibes! </div>

        </div>
        </div>

    <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Lien Pham
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          I’m lost for words when it comes to Nikki and Sean and their staff. Because the experience was beyond perfect. I was feeling down/depressed with my lashing business and their classes brought me back to life. Meeting other amazing artists and having so much fun learning from/with them. It felt like a breakup when the classes ended. It felt sad. Like you wanted to stay or come every weekend. I enjoyed so much of it although of course everyone was nervous but in the end Nikki and Sean and the staff believed in us and gave us confidence most didn’t think we had. All our work was beautiful. The best experience I’ve ever had as a student in my life. I definitely recommend them to ANYONE willing to learn because their technique is what makes their work look so amazing! Love them so much! I will probably be taking other classes they’re offering in the future.
          </div>
        </div>

        </div>

        <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Amber H
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          I went to Cosmetink to pursue their lip blush certification.  This program went far beyond my expectations.  I feel I learned everything I needed to set me up for success in lip blushing.  Beyond that, the teaching methods of the instructors were personable to each student.  They are extremely kind and genuine in wanting you to learn and absorb the information.  It made learning that much more comfortable. It was honestly a joy to be at class and I miss it already!
          </div>
        </div>

        </div>


        <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Taylor Meli
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          I absolutely lovedddd taking my microblading classes with Nikky and Sean!! They were and have been amazing to work with. It was so easy to get started and they made you feel comfortable even though my anxiety was through the roof! I knew I could do it but they made it happen for me. As soon as I had my first model I wanted to do more!! Now I have not stopped practicing my technique and I found another talent that I never knew I could do. I am so blessed that I found them to work with because it was the best decision of my life. They continue to show their support and I know I can count on them to guide me in the right direction. I 100% recommend their  business. You will not be disappointed! Thank you both again for the awesome training experience!💜🙏🏼
          </div>
        </div>

        </div>

        <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Anna Martinez
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          I took the ombré powder brows course. With no prior experience i was a bit nervous. But sean and nikky are amazing teachers ! They made me feel so confident and explained everything so clearly. They have so much patience. Im so glad i made the investment in myself. Definitely worth it 1000% !
          </div>
        </div>

        </div>

    <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Alicia Mecca
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          "I visited Cosmetink over the weekend for their two day ombré brow course. I was excited but also very nervous, as I had a bad experience at another studio learning microblading. Let me just say, these educators are PROFESSIONALS. Not only are they so good at their job, but they were very professional with how they spoke to us and how they broke everything down. They guided us honestly and gracefully. I’m a person with a lot of anxiety and self doubt, and they treated me so so so well. I never once felt like I shouldn’t be pursuing this career. They gave me the confidence and KNOWLEDGE  I needed to complete my model. They are very very skilled in what they do. I aspire to be like them someday. So, overall - they’re wonderful and the course is SO WORTH THE MONEY."
          </div>
        </div>

        </div>

    <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Keiera Kisel
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          "I waited months for a training course with Cosmetink and when it was time I was so excited! I took the ombré powder brow class and it was such a great experience and well worth the wait! Not only are Nikki and Sean super knowledgeable, they are also very talented and want to see their students succeed! I honestly couldn’t have asked for a better training and would absolutely recommend them to anybody who is interested in the industry. I’ll be back for more classes in the future!"
          </div>
        </div>

      </div>

      <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          julia r
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          "I took a beginners ombré brow course at Cosmetink this weekend. Not only do they have an incredibly beautiful studio, but Nikky and Sean are amazing trainers and artists! I learned SO much and left feeling very confident in my abilities and knowledge. The class price was an incredible value and included a top of the line machine, equipment, and pigments. The Cosmetink team is amazing and i cannot recommend them enough. A lot of other trainers in this industry will provide you with cheap/poor quality materials that aren’t good for the artist or client, and leave you not knowing what your next step will be, but that is not the case with Cosmetink. If you’ve ever been wanting to enter the world of cosmetic tattooing, this is your sign to take their classes!! Can’t thank these guys enough and can’t wait to learn more from them."
          </div>
        </div>

      </div>

      <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Lindsey Ruch
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          "I joined the academy for the art of ombré brows class. I have never had an educational experience that was so immersive and fun at the same time. The instructors were incredibly helpful and positive. You never left feeling like you were unsure of what you were learning. Absolutely 5 stars for the amazing hard work of these artists! I will most certainly be coming back for more!"
          </div>
        </div>

      </div>

      <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          corrin bishop
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          "I recently took the ombré powder brow course and couldn’t have had a better introductory experience to this career! The three unique instructors were each super informative on techniques, product and making sure every student was getting their technique perfect. There was plenty of material, demonstration, and practice time. The energy was very positive and encouraging at cosmetink, so if you’re looking to learn look no further."
          </div>
        </div>

      </div>

      <div className="review-container">
        <div className="review-inside">
          <div className="review-name uppercase smaller">
          Hogla Jimenez
          </div>
          <div className="yellow-stars">
          ★★★★★
          </div>
          <br/>
          <div className="review-text smaller">
          "I got the best experience like student, I got certificated on Ombre Powder Brows with the best academy, so proud to have it done with You guys…"
          </div>
        </div>

 

      </div>

      


    </div>

  </div>

          

          



      
    
  

     
          
      </div>
      
      )}